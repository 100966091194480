import { FC, useMemo } from "react";

import { PredefinedMetric } from "@hightouch/lib/query/visual/types/goals";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  Combobox,
  ExternalLinkIcon,
  FilterIcon,
  GroupedCombobox,
  IconButton,
  MetricIcon,
  Paragraph,
  Row,
  StarIcon,
  Text,
  TimeIcon,
  Tooltip,
  WarningIcon,
} from "@hightouchio/ui";
import { noop, uniqBy } from "lodash";
import { Link } from "src/router";

import { getSummaryFromAggregation } from "src/components/analytics/cross-audience-graph/utils";
import { EventColumn } from "src/components/explore/filter-popover/constants";
import { updateConditionAtIndex } from "src/components/explore/utils/condition-builders";
import { IconBox } from "src/components/icon-box";
import {
  AggregationOption,
  AggregationOptions,
  AggregationOptionsWithColumns,
} from "src/pages/metrics/constants";
import {
  AttributionWindow as AttributionWindowType,
  ColumnType,
  FilterableColumn,
  IntervalUnit,
  PropertyCondition,
  initialPropertyCondition,
} from "src/types/visual";

import { AttributionWindow } from "./attribution-window";
import { FilterEvents } from "./filter-events";
import { useAnalyticsContext } from "src/pages/analytics/state";
import {
  EventOrMetricOption,
  MetricSelection,
} from "src/pages/analytics/types";

type MetricBoxProps = {
  aggregationMethod?: AggregationOption;
  attributionWindow?: AttributionWindowType;
  column?: FilterableColumn;
  conditions: PropertyCondition[];
  error?: string;
  isDirty?: boolean;
  isLoading?: boolean;
  metric: EventOrMetricOption | undefined;
  metricOptions: { label: string; options: EventOrMetricOption[] }[];

  onRemove?: () => void;
  onResetForm: () => void;
  onSave?: (metricDefinition: MetricSelection) => void;
  onSelectNewMetric: (id: string) => void;
  onUpdate: (updates: Partial<MetricSelection>) => void;
};

export const MetricBox: FC<Readonly<MetricBoxProps>> = ({
  aggregationMethod = AggregationOption.Count,
  attributionWindow,
  column,
  conditions,
  error,
  isDirty = false,
  isLoading = false,
  metric,
  metricOptions,
  onRemove,
  onResetForm,
  onSave,
  onSelectNewMetric,
  onUpdate,
}) => {
  const { events, metrics, parent, parentModelLoading } = useAnalyticsContext();

  const isNothingSelected = !metric?.id;
  const isEventSelected = metric?.id && metric.eventModelId !== null;
  const selectedMetricDefinition = metrics.find(({ id }) => metric?.id === id);
  const eventId =
    metric?.eventModelId || selectedMetricDefinition?.config.eventModelId;

  const event = useMemo(
    () =>
      eventId
        ? events.find(
            ({ to_model }) => to_model.id.toString() === eventId.toString(),
          )
        : null,
    [eventId, events],
  );
  const eventModelColumns = event
    ? uniqBy(event.to_model.filterable_audience_columns, "name")
    : [];
  const columns = eventModelColumns.filter(
    ({ column_reference }) => column_reference?.type !== "related",
  );
  const numberColumns = eventModelColumns.filter(
    ({ column_reference, type, custom_type }) =>
      (type === ColumnType.Number || custom_type === ColumnType.Number) &&
      column_reference?.type !== "related",
  );

  const handleCreateMetric = () => {
    if (!metric) {
      // TODO(samuel): toast?
      return;
    }

    const data: MetricSelection = {
      ...metric,
      aggregationMethod,
      attributionWindow,
      conditions,
      column,
    };

    onSave?.(data);
  };

  const addCondition = () => {
    onUpdate({ conditions: conditions.concat([initialPropertyCondition]) });
  };

  const updateConditions = (
    index: number,
    updates: Partial<PropertyCondition>,
  ) => {
    const updatedConditions = updateConditionAtIndex(
      index,
      conditions,
      updates,
    );
    onUpdate({ conditions: updatedConditions });
  };

  const removeCondition = (index: number) => {
    onUpdate({ conditions: conditions.filter((_, i) => i !== index) });
  };

  const isPredefinedMetric = Object.values(PredefinedMetric).includes(
    metric?.id as PredefinedMetric,
  );

  return (
    <Column
      bg="white"
      border="1px solid"
      borderColor="base.border"
      borderRadius="md"
      sx={{
        ">*": { px: 2 },
        "> div:last-child": { borderBottomLeftRadius: "md" },
      }}
    >
      {isDirty && (
        <Row
          p={2}
          borderBottom="1px solid"
          borderColor="base.border"
          justify="space-between"
        >
          <Box
            as={Badge}
            background="warning.background"
            borderColor="warning.border"
            color="warning.base"
            textTransform="none"
          >
            Modified
          </Box>
          <Row align="center">
            {onSave && (
              <Box
                sx={{
                  button: {
                    color: "link.default",
                    bg: "transparent",
                    fontWeight: "normal",
                    _hover: {
                      color: "link.hover",
                      bg: "transparent",
                    },
                    _active: {
                      bg: "transparent",
                    },
                  },
                }}
              >
                <Button
                  size="sm"
                  variant="tertiary"
                  onClick={handleCreateMetric}
                >
                  Create metric
                </Button>
              </Box>
            )}
            <Box
              sx={{
                button: {
                  filter: "none",
                  border: "none",
                  fontWeight: "normal",
                  boxShadow: "none",
                  _hover: {
                    bg: "transparent",
                  },
                  _active: {
                    bg: "transparent",
                  },
                },
              }}
            >
              <Button size="sm" variant="warning" onClick={onResetForm}>
                Clear changes
              </Button>
            </Box>
          </Row>
        </Row>
      )}

      <Column p={2} gap={2}>
        <Box
          display="grid"
          gridTemplateColumns={isNothingSelected ? "1fr" : "1fr max-content"}
          gap={2}
          sx={{ input: { width: "100%", boxSizing: "border-box" } }}
        >
          <GroupedCombobox
            isLoading={isLoading}
            optionGroups={metricOptions}
            optionAccessory={({ eventModelId }) => ({
              type: "icon",
              icon: () => (
                <IconBox
                  bg={eventModelId ? EventColumn.color : "peridot.400"}
                  boxSize="20px"
                  icon={eventModelId ? <TimeIcon /> : <MetricIcon />}
                  iconSize="14px"
                />
              ),
            })}
            optionLabel={(option) => option.name ?? "<name missing>"}
            optionValue={(option) => option}
            placeholder="Select a metric..."
            value={metric}
            variant="heavy"
            width="100%"
            onChange={(updates) => {
              if (!updates) {
                return;
              }

              onSelectNewMetric(updates.id);
            }}
          />

          {!isNothingSelected && (
            <Row gap={2}>
              {isEventSelected && (
                <Tooltip message="Save metric">
                  <IconButton
                    aria-label="Save metric."
                    icon={StarIcon}
                    onClick={handleCreateMetric}
                  />
                </Tooltip>
              )}
              {metric?.id &&
                !isPredefinedMetric &&
                metric.eventModelId === null && (
                  <Link href={`/metrics/${metric.id}`} isExternal>
                    <Tooltip message="View metric">
                      <IconButton
                        icon={ExternalLinkIcon}
                        aria-label="Link to selected metric"
                        variant="tertiary"
                        onClick={noop}
                      />
                    </Tooltip>
                  </Link>
                )}
              {onRemove && (
                <Tooltip message="Remove metric">
                  <IconButton
                    aria-label="Remove metric."
                    icon={CloseIcon}
                    onClick={onRemove}
                  />
                </Tooltip>
              )}
            </Row>
          )}
        </Box>

        {isEventSelected ? (
          <Row gap={1} flexWrap="wrap">
            <Combobox
              options={AggregationOptions}
              size="sm"
              width="100%"
              value={aggregationMethod}
              variant="alternative"
              onChange={(value) => {
                if (value !== undefined) {
                  if (AggregationOptionsWithColumns.includes(value)) {
                    const firstColumn = numberColumns?.[0];
                    onUpdate({ aggregationMethod: value, column: firstColumn });
                  } else {
                    onUpdate({ aggregationMethod: value, column: undefined });
                  }
                }
              }}
            />

            {AggregationOptionsWithColumns.includes(aggregationMethod) && (
              <Combobox
                optionLabel={(option) => option.alias ?? option.name ?? ""}
                optionValue={(option) => option}
                options={
                  aggregationMethod === AggregationOption.CountDistinctProperty
                    ? columns
                    : numberColumns
                }
                emptyOptionsMessage="No `number` columns"
                placeholder="Select a property..."
                size="sm"
                width="100%"
                value={column}
                variant="alternative"
                onChange={(value) => onUpdate({ column: value })}
              />
            )}
          </Row>
        ) : (
          <>
            {metric?.description && (
              <Paragraph color="text.secondary">{metric.description}</Paragraph>
            )}
            {metric?.id && !isPredefinedMetric && (
              <Paragraph ml={2} mr={8} size="sm">
                {getSummaryFromAggregation(
                  aggregationMethod,
                  event?.to_model.name ?? "event",
                  column ? column.alias ?? column.name : undefined,
                )}
              </Paragraph>
            )}
          </>
        )}
      </Column>

      {metric?.id && !isPredefinedMetric && (
        <>
          {attributionWindow && (
            <AttributionWindow
              attributionWindow={attributionWindow}
              onChange={(value) => onUpdate({ attributionWindow: value })}
              onRemove={() => onUpdate({ attributionWindow: undefined })}
            />
          )}
          {conditions.length > 0 && (
            <FilterEvents
              isLoading={parentModelLoading}
              options={eventModelColumns}
              parent={parent}
              subconditions={conditions}
              onChange={(conditionIndex, value) =>
                updateConditions(conditionIndex, value)
              }
              onAddSubcondition={addCondition}
              onRemoveSubcondition={removeCondition}
            />
          )}

          {(!attributionWindow || conditions?.length === 0) && (
            <Column borderTop="1px solid" borderColor="base.border" p={2}>
              <ButtonGroup size="lg">
                {!attributionWindow && (
                  <Button
                    icon={TimeIcon}
                    size="sm"
                    variant="tertiary"
                    onClick={() =>
                      onUpdate({
                        attributionWindow: {
                          quantity: 0,
                          unit: IntervalUnit.Day,
                        },
                      })
                    }
                  >
                    Attribution window
                  </Button>
                )}
                {conditions.length === 0 && (
                  <Button
                    icon={FilterIcon}
                    size="sm"
                    variant="tertiary"
                    onClick={addCondition}
                  >
                    Filter events
                  </Button>
                )}
              </ButtonGroup>
            </Column>
          )}
        </>
      )}

      {error && (
        <Box
          alignItems="center"
          display="grid"
          gridTemplateColumns="28px 1fr 32px"
          color="danger.base"
          fontSize="20px"
          gap={2}
          pb={2}
        >
          <WarningIcon ml={2} />
          <Text color="danger.base" size="sm">
            {error}
          </Text>
        </Box>
      )}
    </Column>
  );
};
