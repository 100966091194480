import { FC, useEffect, useState } from "react";

import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormField,
  SectionHeading,
  MultiSelect,
  Select,
  Switch,
  TextInput,
  Column,
  SlugInput,
  RadioGroup,
  Radio,
  Text,
  Row,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEqual } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "src/router";

import { ActionBar } from "src/components/action-bar";
import { FeatureField } from "src/components/feature-gates";
import {
  DiscardButton,
  Form,
  FormActions,
  useHightouchForm,
} from "src/components/form";
import { useUser } from "src/contexts/user-context";
import {
  useIsWorkspaceSlugAvailableQuery,
  useUpdateWorkspaceMutation,
  useVerifiedDomainsQuery,
  useUpdateWorkspaceDomainsMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import { HightouchRegions } from "src/utils/regions";
import { RoleSelect } from "src/components/role-select";
import slugify from "@sindresorhus/slugify";
import notCondition from "src/assets/not-condition.svg";

import { AudienceFilterNullBehaviorModal } from "./audience-filter-null-behavior-modal";
import { Term } from "src/components/term";

type FormState = {
  name: string;
  slug: string;
  approvals_required: boolean;
  sync_templates_only: boolean;
  assign_default_role_to_sso: boolean;
  audience_snapshotting_enabled: boolean;
  default_role_id: string;
  visible: Array<string>;
  joinable: Array<string>;
  visual_query_not_filter_exclude_nulls: boolean;
};

enum WorkspaceFeatureFlag {
  VisualQueryNotFilterExcludeNulls = "visual_query.not_filter.exclude_nulls",
}

export const Workspace: FC = () => {
  const { workspace } = useUser();

  const [
    showAudienceFilterNullBehaviorModal,
    setShowAudienceFilterNullBehaviorModal,
  ] = useState(false);

  const domainsQuery = useVerifiedDomainsQuery(undefined, { suspense: true });
  const workspaceMutation = useUpdateWorkspaceMutation();
  const domainMutation = useUpdateWorkspaceDomainsMutation();

  const initialVisible =
    domainsQuery.data?.getVerifiedDomains?.filter(
      (domain) => workspace?.visible_to?.includes(domain),
    ) ?? [];
  const initialJoinable =
    domainsQuery.data?.getVerifiedDomains?.filter(
      (domain) => workspace?.joinable_to?.includes(domain),
    ) ?? [];

  const visualQueryNotFilterExcludeNullsFlag = Boolean(
    workspace?.feature_flags[
      WorkspaceFeatureFlag.VisualQueryNotFilterExcludeNulls
    ],
  );

  const onSubmit = async (input: FormState) => {
    const {
      joinable,
      visible,
      visual_query_not_filter_exclude_nulls,
      ...rest
    } = input;

    await workspaceMutation.mutateAsync({
      id: workspace?.id,
      input: rest,
      append: {
        feature_flags: {
          [WorkspaceFeatureFlag.VisualQueryNotFilterExcludeNulls]:
            visual_query_not_filter_exclude_nulls,
        },
      },
    });

    if (input.slug !== workspace?.slug) {
      window.location.replace(
        `${window.location.origin}/${slug}/settings/workspace`,
      );
    }

    if (
      !isEqual(initialVisible, visible) ||
      !isEqual(initialJoinable, joinable)
    ) {
      await domainMutation.mutateAsync({
        visibleTo: visible,
        joinableTo: joinable,
      });
    }
  };

  const form = useHightouchForm<FormState>({
    onSubmit,
    values: {
      name: workspace?.name ?? "",
      slug: workspace?.slug ?? "",
      approvals_required: workspace?.approvals_required ?? false,
      sync_templates_only: workspace?.sync_templates_only ?? false,
      assign_default_role_to_sso:
        workspace?.assign_default_role_to_sso ?? false,
      audience_snapshotting_enabled:
        workspace?.audience_snapshotting_enabled ?? false,
      default_role_id: workspace?.default_role_id ?? "",
      joinable: initialJoinable,
      visible: initialVisible,
      visual_query_not_filter_exclude_nulls:
        visualQueryNotFilterExcludeNullsFlag,
    },
  });

  const slug = form.watch("slug");

  return (
    <>
      <Column gap={16} pb={10}>
        <Form form={form}>
          <General />
          <Authorized domains={domainsQuery.data?.getVerifiedDomains ?? []} />

          <ActionBar center={false}>
            {form.formState.dirtyFields
              .visual_query_not_filter_exclude_nulls ? (
              <ButtonGroup>
                <Button
                  size="lg"
                  variant="primary"
                  onClick={() => setShowAudienceFilterNullBehaviorModal(true)}
                >
                  Save changes
                </Button>
                <DiscardButton />
              </ButtonGroup>
            ) : (
              <FormActions />
            )}
          </ActionBar>
        </Form>
      </Column>
      <AudienceFilterNullBehaviorModal
        isOpen={showAudienceFilterNullBehaviorModal}
        onClose={() => setShowAudienceFilterNullBehaviorModal(false)}
        onConfirm={form.submit}
      />
    </>
  );
};

const regionOptions = Object.entries(HightouchRegions).map(
  ([id, { friendlyName }]) => {
    return { label: friendlyName, value: id };
  },
);

const General: FC = () => {
  const { workspace, user } = useUser();
  const permissionV2Enabled = user?.permissions_v2_enabled;
  const { data: entitlementsData } = useEntitlements(false, true);
  const { appAllowApprovalFlows } = useFlags();
  const { clearErrors, setError, control, watch } = useFormContext<FormState>();
  const slug = watch("slug");

  const hasCustomerStudio = entitlementsData.entitlements.audiences;
  const isBusinessTier = workspace?.organization?.plan?.sku === "business_tier";
  const approvalFlowsDisabled = !isBusinessTier && !appAllowApprovalFlows;

  const slugQuery = useIsWorkspaceSlugAvailableQuery(
    {
      slug: String(slug),
    },
    {
      enabled: slug !== workspace?.slug,
    },
  );

  useEffect(() => {
    if (slugQuery.data) {
      if (!slugQuery.data?.isWorkspaceSlugAvailable) {
        setError("slug", { message: "This slug is not available" });
      } else {
        clearErrors("slug");
      }
    }
  }, [slugQuery.data?.isWorkspaceSlugAvailable]);

  return (
    <Column gap={6}>
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <FormField label="Workspace name">
            <TextInput {...field} />
          </FormField>
        )}
      />
      <Controller
        name="slug"
        render={({ field, fieldState: { error } }) => (
          <FormField
            error={slugQuery.isLoading ? undefined : error?.message}
            label="Workspace slug"
          >
            <SlugInput
              prefix="app.hightouch.com/"
              {...field}
              validationState={
                slugQuery.isLoading ? "validating" : error ? "invalid" : "valid"
              }
              onChange={(event) =>
                field.onChange(
                  slugify(event?.target.value, { preserveTrailingDash: true }),
                )
              }
            />
          </FormField>
        )}
      />

      {workspace?.organization && (
        <FormField label="Billing organization">
          <TextInput isReadOnly value={workspace.organization.name} />
        </FormField>
      )}

      <FormField
        label="Workspace region"
        tip="Workspace region can't be changed after workspace is created."
      >
        <Select
          isDisabled
          options={regionOptions}
          value={workspace?.region ?? undefined}
          onChange={() => {}}
        />
      </FormField>

      {!permissionV2Enabled && (
        <Controller
          control={control}
          name="default_role_id"
          render={({ field }) => (
            <FormField
              description="The role assigned to people that join this workspace."
              label="Default role"
            >
              <RoleSelect isDisabled={!isBusinessTier} {...field} />
            </FormField>
          )}
        />
      )}

      {workspace?.organization?.auth0_connections && !permissionV2Enabled && (
        <Controller
          control={control}
          name="assign_default_role_to_sso"
          render={({ field }) => (
            <FormField
              description="By turning this off, single sign on users must have their group mapped to a Hightouch role in order to be a member of this workspace."
              label="Use default role for SSO users"
              tip={
                isBusinessTier
                  ? undefined
                  : "Only available for Business Tier workspaces."
              }
            >
              <Switch
                isChecked={Boolean(field.value)}
                onChange={field.onChange}
              />
            </FormField>
          )}
        />
      )}

      <Controller
        control={control}
        name="approvals_required"
        render={({ field }) => (
          <FeatureField
            description={
              <>
                Enabling this feature will require groups with the "Workspace
                draft editor" role to get approval before they can publish
                changes to models or syncs.
                <br />
              </>
            }
            enabled={!approvalFlowsDisabled}
            featureDetails={{
              pitch: "Approval flows for publishing models and syncs",
              description:
                "Hightouch allows you to require specific users to get approval before publishing changes to models or syncs, or before creating new ones.",
              bullets: [
                "Provide admins with a detailed changelog to streamline the review process",
                "Allow users to save temporary resources as drafts until they are ready for approval",
              ],
              image: {
                src: "https://cdn.sanity.io/images/pwmfmi47/production/c28a70cae8685236c2d29e6f65331d09bc1e5e72-1246x838.png",
              },
            }}
            featureName="approval flows"
            label="Require approvals"
          >
            <Switch
              isChecked={Boolean(field.value)}
              isDisabled={approvalFlowsDisabled}
              onChange={field.onChange}
            />
          </FeatureField>
        )}
      />

      {hasCustomerStudio && (
        <>
          <Controller
            control={control}
            name="sync_templates_only"
            render={({ field }) => (
              <FormField
                description="By turning this on, users can only sync audiences using sync templates and cannot detach syncs from templates."
                label="Require sync templates for audience activation"
              >
                <Switch
                  isChecked={Boolean(field.value)}
                  onChange={field.onChange}
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="visual_query_not_filter_exclude_nulls"
            render={({ field }) => (
              <Box maxWidth={576}>
                <FormField
                  description="How should audience filters like “does not equal” or “does not contain” behave with respect to null values? For example, if a user has country = null, should they be included in this audience?"
                  label={
                    <Text>
                      Null-handling behavior for{" "}
                      <Term
                        message={
                          <Column gap={3} alignItems="center">
                            <Column alignItems="center">
                              <Row>Strings:</Row>
                              <Row>
                                “Does not equal”, “Does not contain”, “Does not
                                start with”, “Does not end with”
                              </Row>
                            </Column>

                            <Column alignItems="center">
                              <Row>Booleans:</Row>
                              <Row>“Does not equal”</Row>
                            </Column>

                            <Column alignItems="center">
                              <Row>Numbers:</Row>
                              <Row>“Does not equal”</Row>
                            </Column>
                          </Column>
                        }
                      >
                        <span>“not” filters</span>
                      </Term>
                    </Text>
                  }
                >
                  <Box as="img" src={notCondition} my={4} />
                  <RadioGroup value={field.value} onChange={field.onChange}>
                    <Radio
                      label="Treat null values like any other value"
                      badge={
                        <Text color="text.secondary" ml={-1}>
                          (default)
                        </Text>
                      }
                      description='The user will satisfy this condition because null literally does not equal "USA". Therefore, the user will be included in the audience.'
                      value={false}
                    />
                    <Radio
                      label="Use special behavior for null values"
                      description="This option will ensure that null values never satisfy “not” filters. Therefore, the user will be excluded from the audience."
                      value={true}
                    />
                  </RadioGroup>
                </FormField>
              </Box>
            )}
          />
        </>
      )}
    </Column>
  );
};

const Authorized: FC<{ domains: Array<string> }> = ({ domains = [] }) => {
  const { workspace, user } = useUser();
  const permissionV2Enabled = user?.permissions_v2_enabled;
  const navigate = useNavigate();
  const { control } = useFormContext<FormState>();

  if (permissionV2Enabled) {
    return null;
  }

  if (!workspace?.organization?.can_invite_users) {
    return (
      <Column gap={6}>
        <SectionHeading isTruncated>Authorized domains</SectionHeading>
        <Alert
          message="Joining the workspace by email domain is disabled in this workspace. Please use the SSO identity provider connected to your organization. You can change this setting on your SSO tab."
          title="Unavailable feature"
          type="info"
          variant="inline"
          actions={
            <Button
              variant="secondary"
              onClick={() => navigate("/settings/sso")}
            >
              View SSO settings
            </Button>
          }
        />
      </Column>
    );
  }

  return (
    <Column gap={6}>
      <SectionHeading isTruncated>Workspace authorization</SectionHeading>
      <Controller
        control={control}
        name="joinable"
        render={({ field }) => (
          <FormField
            description="Users with these domains will be able to see this workspace and request to join."
            label="Which domains can see this workspace?"
          >
            <MultiSelect
              optionLabel={(domain) => domain}
              optionValue={(domain) => domain}
              options={domains}
              placeholder="Select domains..."
              width="sm"
              {...field}
            />
          </FormField>
        )}
      />
      <Controller
        control={control}
        name="visible"
        render={({ field }) => (
          <FormField
            description="Users with these domains will be able to see and join this workspace without approval."
            label="Which domains can auto-join this workspace?"
          >
            <MultiSelect
              optionLabel={(domain) => domain}
              optionValue={(domain) => domain}
              options={domains}
              placeholder="Select domains..."
              width="sm"
              {...field}
            />
          </FormField>
        )}
      />
    </Column>
  );
};
