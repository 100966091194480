import { FC } from "react";

import {
  Row,
  Column,
  Text,
  ArrowRightIcon,
  Badge,
  ChevronLeftIcon,
} from "@hightouchio/ui";
import type { ElementOf } from "ts-essentials";

import { Link, Navigate, Outlet, useParams } from "src/router";
import { MetadataBar, MetadataLabel } from "src/components/metadata-bar";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import {
  ResourceToPermission,
  SyncAttemptQuery,
  SyncQuery,
  useSyncAttemptQuery,
  useSyncQuery,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";
import {
  getObjectName,
  getSyncAttemptDiff,
  syncRunStatusOrUnknownStatus,
} from "src/utils/syncs";
import * as time from "src/utils/time";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { PermissionProvider } from "src/components/permission/permission-context";
import { SyncRunStatusIndicator } from "src/components/syncs/sync-run-status-indicator";
import { Warning } from "src/components/warning";
import { RouteTabs } from "src/components/route-tabs";

export type Context = {
  sync: ElementOf<SyncQuery["syncs"]>;
  attempt: ElementOf<SyncAttemptQuery["sync_attempts"]> | undefined;
};

export const SyncRunWrapper: FC = () => {
  const { run_id: runId, sync_id: syncId } = useParams<{
    run_id: string;
    sync_id: string;
  }>();

  const {
    data: sync,
    error: syncError,
    isLoading: syncIsLoading,
  } = useSyncQuery(
    {
      id: syncId ?? "",
    },
    {
      enabled: Boolean(syncId),
      select: (data) => data.syncs[0],
    },
  );

  const {
    data: attempt,
    error: syncAttemptError,
    isLoading: syncAttemptLoading,
  } = useSyncAttemptQuery(
    {
      syncRequestId: runId ?? "",
    },
    {
      enabled: Boolean(runId),
      select: (data) => data.sync_attempts[0],
      refetchInterval: (attempt) => {
        if (attempt?.finished_at) {
          // Stop polling if this sync attempt is finished.
          return false;
        }

        return 5000;
      },
    },
  );

  if (!syncId || !runId) {
    return <Navigate to="/syncs" />;
  }

  if (syncError || syncAttemptError) {
    return (
      <Warning title="Something went wrong" subtitle="Please try again later" />
    );
  }

  if (syncIsLoading || syncAttemptLoading) {
    return <PageSpinner />;
  }

  if (!sync) {
    return <Warning title="Sync not found" />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={sync.draft || false}
      resourceId={syncId}
      resourceType={ResourceToPermission.Sync}
    >
      <SyncRunLayout sync={sync} attempt={attempt} />
    </DraftProvider>
  );
};

const SyncRunLayout = ({ sync, attempt }: Context) => {
  const { editingDraft } = useDraft();

  const syncId = sync.id;
  const runId = attempt?.sync_request?.id;

  const syncRequest = attempt?.sync_request;
  const syncError =
    syncRequest?.error ??
    (attempt?.error ? { message: attempt.error } : undefined);
  const resyncReason = syncRequest?.resync_reason;
  const plannerType = syncRequest?.planner_type;
  const errorCodeDetail = syncRequest?.error_code_detail;

  if (syncError && errorCodeDetail) syncError.errorCodeDetail = errorCodeDetail;

  const diff = getSyncAttemptDiff(attempt);

  const added =
    plannerType === "all" && !syncError
      ? syncRequest?.sync_request_diff?.added_count ?? 0
      : (syncRequest?.add_executed ?? 0) - (diff?.rejected?.add ?? 0);

  const changed =
    (syncRequest?.change_executed ?? 0) - (diff?.rejected?.change ?? 0);
  const removed =
    (syncRequest?.remove_executed ?? 0) - (diff?.rejected?.remove ?? 0);
  const successfulRows = added + changed + removed;

  const rejectedRows =
    plannerType === "all" && syncError
      ? syncRequest?.sync_request_diff?.added_count ?? 0
      : (diff?.rejected?.add ?? 0) +
        (diff?.rejected?.change ?? 0) +
        (diff?.rejected?.remove ?? 0);

  const pendingRows = syncRequest?.query_run?.pending_rows ?? 0;
  const showPendingRows = pendingRows > 0;

  const context: Context = { sync, attempt };

  return (
    <PermissionProvider
      permission={{
        v2: {
          resource: "sync",
          grant: "can_update",
          id: sync?.id,
        },
      }}
    >
      <Row fontSize="3xl" color="link.default" mx={4} mt={4}>
        <Link
          href={
            editingDraft
              ? `/partner/syncs/${syncId}?editing=true`
              : `/partner/syncs/${syncId}`
          }
        >
          <ChevronLeftIcon />
          Back to sync
        </Link>
      </Row>

      <Column width="100%" flex={1}>
        <Row
          align="center"
          justify="space-between"
          width="100%"
          mb={2}
          gap={6}
          p={4}
        >
          <Row align="center" gap={4}>
            <Row align="center" gap={3}>
              <IntegrationIcon
                size={8}
                src={sync?.segment?.connection?.definition.icon}
                name={sync?.segment?.connection?.definition.name}
              />
              <Column overflow="hidden">
                <Text
                  size="sm"
                  fontWeight="semibold"
                  color="text.tertiary"
                  textTransform="uppercase"
                >
                  Model
                </Text>
                <Text fontWeight="medium" size="lg" isTruncated>
                  {sync?.segment?.name}
                </Text>
              </Column>
            </Row>
            <Row fontSize="3xl" color="text.placeholder">
              <ArrowRightIcon />
            </Row>
            <Row align="center" gap={3}>
              <IntegrationIcon
                size={8}
                src={sync?.destination?.definition.icon}
                name={sync?.destination?.definition.name}
              />
              <Column overflow="hidden">
                <Text
                  size="sm"
                  fontWeight="semibold"
                  color="text.tertiary"
                  textTransform="uppercase"
                >
                  Destination
                </Text>
                <Row align="center" gap={3}>
                  <Text fontWeight="medium" size="lg" isTruncated>
                    {sync?.destination?.name}
                  </Text>
                  {sync?.config?.object && (
                    <Badge size="sm">{getObjectName(sync.config.object)}</Badge>
                  )}
                </Row>
              </Column>
            </Row>
          </Row>
        </Row>

        <Row align="center" mx={4}>
          <MetadataBar>
            <Column>
              <MetadataLabel>Run status</MetadataLabel>
              <SyncRunStatusIndicator
                status={syncRunStatusOrUnknownStatus(
                  syncRequest?.status_computed,
                )}
                completionRatio={syncRequest?.completion_ratio || null}
              />
            </Column>
            <Column>
              <MetadataLabel>Started at</MetadataLabel>
              <Text>
                {syncRequest?.created_at &&
                  time.formatDatetime(syncRequest.created_at)}
              </Text>
            </Column>
            {attempt?.finished_at && (
              <Column>
                <MetadataLabel>Duration</MetadataLabel>
                <Text>
                  {time.diff(attempt?.created_at, attempt?.finished_at)}
                </Text>
              </Column>
            )}
            <Column>
              <MetadataLabel>Run ID</MetadataLabel>
              <Text>{runId}</Text>
            </Column>
            {resyncReason && (
              <Column>
                <MetadataLabel>Resync reason</MetadataLabel>
                <Text>
                  {{
                    "explicit-request": "Triggered by user",
                    "added-fields": "Fields were added",
                    "changed-config": "Configuration was changed",
                    "changed-mappings": "Mappings changed",
                    "changed-source-types": "Source types changed",
                    "changed-primary-key": "Model primary key was changed",
                    "retry-failed-resync": "Retrying failed resync",
                    "archive-mode-resync": "Resyncing for archive mode",
                    "next-diff-mode-override": "Next sync run override",
                  }[resyncReason] ?? resyncReason}
                </Text>
              </Column>
            )}
          </MetadataBar>
        </Row>
        <RouteTabs
          tabs={[
            {
              title: "Successful",
              path: "successful",
              count: successfulRows,
            },
            {
              title: "Pending",
              path: "pending",
              count: pendingRows,
              isHidden: !showPendingRows,
            },
            {
              title: "Rejected",
              path: "rejected",
              count: rejectedRows,
            },
            {
              title: "Configuration",
              path: "configuration",
            },
          ]}
          depth={6}
          mx={4}
        />
        <Column p={4} pb={10}>
          <Outlet context={context} />
        </Column>
      </Column>
    </PermissionProvider>
  );
};
