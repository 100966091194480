import { ComponentType, FC, useEffect, useRef, useState } from "react";

import {
  ArrayIcon,
  Box,
  Button,
  ButtonGroup,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  CloseIcon,
  Column,
  DollarIcon,
  ObjectIcon,
  Portal,
  Row,
  SelectorIcon,
  SparkleIcon,
  TableIcon,
  Text,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  TraitIcon,
  useDisclosure,
  useToast,
} from "@hightouchio/ui";
import get from "lodash/get";
import { ExtendedOption } from "../../../../../formkit";
import { toExtendedOption } from "../../../../../formkit/src/api/components/option";
import {
  JsonColumnProps,
  Mapping,
  MappingType,
  OverwriteStandardInputWithArrayProps,
  Props,
  SelectedOptionContext,
} from "../types";
import {
  formatValue,
  isCalculatedVisualColumnType,
  NEW_STATIC_ICONS,
} from "../utils";
import { ArrayPropertiesInput } from "./array-properties-input";
import { StandardInput } from "./standard-input";
import { INPUTS } from "./types";
import { useFormkitContext } from "../formkit-context";
import { isEmpty } from "lodash";

const isValueEmpty = (value: any): boolean => {
  return (
    (value.type === MappingType.STANDARD &&
      typeof value.from === "undefined") ||
    (value.type === MappingType.VARIABLE &&
      typeof value.variable === "undefined") ||
    (value.type === MappingType.STATIC && typeof value.value === "undefined") ||
    (value.type === MappingType.TEMPLATE &&
      typeof value.template === "undefined") ||
    (value.type === MappingType.OBJECT && typeof value.from === "undefined") ||
    (value.type === MappingType.ARRAY &&
      (typeof value.from === "undefined" ||
        typeof value.children === "undefined"))
  );
};

type ValueProp = {
  children?: React.ReactNode;
  from?: string;
  template?: string;
  type?: string;
  value?: string;
  variable?: string;
};

export const Mapper: FC<
  Readonly<
    Props & {
      enableInLineMapper?: boolean;
      isClearable?: boolean;
      jsonColumnProperties: JsonColumnProps;
      mappingTypes?: MappingType[];
      onChangeJsonColumnProperties: React.Dispatch<
        React.SetStateAction<JsonColumnProps>
      >;
      onReloadEligibleInlineMapperColumns: (
        currentSelectedColumn?: string,
      ) => void;
      parentMapping?: Mapping;
      selectedOption: SelectedOptionContext | ExtendedOption | undefined;
    } & OverwriteStandardInputWithArrayProps
  >
> = ({
  columnOptions,
  enableInLineMapper,
  isClearable,
  isDisabled = false,
  isError,
  jsonColumnProperties,
  mappingTypes,
  onChange,
  onChangeJsonColumnProperties,
  onReloadEligibleInlineMapperColumns,
  overwriteColumnsWithArrayProps,
  parentMapping,
  placeholder,
  selectedOption,
  templates,
  ...props
}) => {
  const [value, setValue] = useState<ValueProp>({});
  const { isOpen, onClose, onToggle } = useDisclosure();
  const searchRef = useRef<HTMLInputElement>(null);
  const { toast } = useToast();
  const { model } = useFormkitContext();

  const standardShouldBeEnabled =
    mappingTypes && mappingTypes.length
      ? mappingTypes.includes(MappingType.STANDARD)
      : true;
  const standardEnabled = standardShouldBeEnabled && model;
  const fieldEnabled = standardShouldBeEnabled && !model;
  const staticEnabled =
    mappingTypes && mappingTypes.length
      ? mappingTypes.includes(MappingType.STATIC)
      : true;
  const variableEnabled =
    mappingTypes && mappingTypes.length
      ? mappingTypes.includes(MappingType.VARIABLE)
      : Boolean(model);
  const templateEnabled =
    mappingTypes && mappingTypes.length
      ? mappingTypes.includes(MappingType.TEMPLATE)
      : true;
  const objectEnabled =
    mappingTypes && mappingTypes.length
      ? mappingTypes.includes(MappingType.OBJECT)
      : true;
  const arrayShouldBeEnabled =
    mappingTypes && mappingTypes.length
      ? mappingTypes.includes(MappingType.ARRAY)
      : true;
  const arrayEnabled = model && arrayShouldBeEnabled;
  const arrayFieldEnabled = !model && arrayShouldBeEnabled;

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  let fieldType: string;
  if (!arrayEnabled && arrayFieldEnabled && value.type === MappingType.ARRAY) {
    fieldType = MappingType.ARRAY_FIELD;
  } else {
    fieldType = value.type ?? MappingType.STANDARD;
  }
  if (fieldEnabled && fieldType === MappingType.STANDARD) {
    fieldType = MappingType.FIELD;
  }

  let MappingInput;
  if (fieldType === MappingType.STANDARD && overwriteColumnsWithArrayProps) {
    MappingInput = ArrayPropertiesInput;
  } else {
    MappingInput = fieldType ? INPUTS[fieldType] : StandardInput;
  }
  const selectedType =
    fieldType === MappingType.ARRAY_FIELD ? value.type : fieldType;

  const extractedType = toExtendedOption(selectedOption)?.extendedType?.type;
  const isFieldDisabled =
    isDisabled || props.value.from?.type === MappingType.BOOSTED;

  const shouldEnableArrayInlineMapper =
    (enableInLineMapper && extractedType === "ARRAY") ||
    (enableInLineMapper && !extractedType);

  const shouldEnableObjectInlineMapper =
    (enableInLineMapper && extractedType === "OBJECT") ||
    (enableInLineMapper && !extractedType);

  const handleApply = (onClose, valueToApply) => {
    const newValue = { ...value, ...valueToApply };
    if (newValue?.type === MappingType.ARRAY && !newValue?.from) {
      toast({
        id: "array-inline-mapper",
        title: "Please select a column before proceeding.",
        variant: "error",
      });
    } else if (
      parentMapping?.type === MappingType.ARRAY &&
      newValue?.type === MappingType.ARRAY &&
      parentMapping?.from !== newValue?.from
    ) {
      toast({
        id: "array-inline-mapper",
        title: "You must select the same column as the top-level mapping.",
        variant: "error",
      });
    } else {
      if (newValue.type === "boosted") {
        // 'boosted' is not a top level type
        // the 'boosted' type lives in `newValue`, so set the
        // top level type back to 'standard'
        onChange({ ...newValue, type: "standard" });
      } else {
        onChange(newValue);
      }

      onClose();
    }
  };

  const onChangeToUse =
    fieldType === MappingType.OBJECT ||
    fieldType === MappingType.STATIC ||
    fieldType === MappingType.TEMPLATE ||
    fieldType === MappingType.FIELD ||
    fieldType === MappingType.ARRAY_FIELD
      ? setValue
      : handleApply;

  const toggleButtons = [
    { enabled: standardEnabled, label: "Column", value: MappingType.STANDARD },
    { enabled: fieldEnabled, label: "Field", value: MappingType.FIELD },
    {
      enabled: staticEnabled,
      label: "Static value",
      value: MappingType.STATIC,
    },
    {
      enabled: variableEnabled,
      label: "Variable value",
      value: MappingType.VARIABLE,
    },
    {
      enabled: templateEnabled,
      label: "Template",
      value: MappingType.TEMPLATE,
    },
    {
      enabled: shouldEnableObjectInlineMapper && objectEnabled,
      label: "Create an object",
      value: MappingType.OBJECT,
    },
    {
      enabled:
        shouldEnableArrayInlineMapper && (arrayEnabled || arrayFieldEnabled),
      label: "Create an array",
      value: MappingType.ARRAY,
    },
  ];

  const enabledToggleButtons = toggleButtons.filter((button) => button.enabled);
  const fieldValue = { ...props.value };
  if (fieldType) fieldValue.type = fieldType;

  return (
    <ChakraPopover
      isLazy
      initialFocusRef={searchRef}
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-start"
    >
      <ChakraPopoverTrigger>
        <Row
          _active={{
            bg: "white",
            borderColor: isError ? "danger.600" : "primary.base",
          }}
          _hover={{
            borderColor: isError ? "danger.600" : "gray.border",
          }}
          alignItems="center"
          backgroundColor="base.lightBackground"
          border="1px"
          borderColor={isError ? "danger.600" : "base.border"}
          borderRadius="md"
          boxShadow="sm"
          cursor={isFieldDisabled ? "not-allowed" : undefined}
          display="flex"
          justifyContent="space-between"
          onClick={isFieldDisabled ? undefined : onToggle}
          opacity={isDisabled ? 0.4 : undefined}
          px={2}
          py={1}
          transition="200ms all"
          width="100%"
        >
          <Value
            placeholder={placeholder}
            value={fieldValue}
            selectedOptionSemanticType={
              toExtendedOption(selectedOption)?.extendedType?.semanticColumnType
            }
          />
          {isClearable && !isValueEmpty(props.value) ? (
            <Box
              as={CloseIcon}
              color="text.primary"
              cursor="pointer"
              fontSize="16px"
              onClick={onChange}
            />
          ) : (
            <Box as={SelectorIcon} color="text.secondary" fontSize="20px" />
          )}
        </Row>
      </ChakraPopoverTrigger>
      <Portal>
        <ChakraPopoverContent
          height="329px"
          maxWidth="786px"
          overflow="hidden"
          width="100vw"
        >
          <ChakraPopoverBody
            p={0}
            flex={1}
            display="flex"
            flexDirection="column"
          >
            <>
              <Column flex="1">
                <Box p={3}>
                  <ToggleButtonGroup
                    onChange={(type) => {
                      const fields = [
                        MappingType.FIELD,
                        MappingType.ARRAY_FIELD,
                        MappingType.ARRAY,
                      ];
                      if (
                        fields.includes(type as any) &&
                        fields.includes(fieldType as any)
                      ) {
                        setValue({ ...value, type });
                      } else {
                        setValue({ type });
                      }
                    }}
                    value={selectedType || enabledToggleButtons?.[0]?.value}
                  >
                    {enabledToggleButtons.map((button) => (
                      <ToggleButton
                        key={button.value}
                        label={button.label}
                        value={button.value}
                      />
                    ))}
                  </ToggleButtonGroup>
                </Box>

                <MappingInput
                  onClose={onClose}
                  columnOptions={columnOptions}
                  jsonColumnProperties={jsonColumnProperties}
                  onChange={onChangeToUse}
                  onChangeJsonColumnProperties={onChangeJsonColumnProperties}
                  onReloadEligibleInlineMapperColumns={
                    onReloadEligibleInlineMapperColumns
                  }
                  overwriteColumnsWithArrayProps={
                    overwriteColumnsWithArrayProps
                  }
                  ref={searchRef}
                  templates={templates}
                  value={value}
                />
              </Column>

              {(value.type === MappingType.OBJECT ||
                value.type === MappingType.STATIC ||
                value.type === MappingType.TEMPLATE ||
                fieldType === MappingType.FIELD ||
                fieldType === MappingType.ARRAY_FIELD) && (
                <Row alignItems="center" justifyContent="right" p={3}>
                  <ButtonGroup>
                    <Button
                      onClick={() => handleApply(onClose, value)}
                      variant="primary"
                    >
                      Apply
                    </Button>
                  </ButtonGroup>
                </Row>
              )}
            </>
          </ChakraPopoverBody>
        </ChakraPopoverContent>
      </Portal>
    </ChakraPopover>
  );
};

const Value: FC<
  Readonly<{
    placeholder: string | undefined;
    value: any;
    selectedOptionSemanticType: string | undefined;
  }>
> = ({ placeholder, value, selectedOptionSemanticType }) => {
  const { model } = useFormkitContext();
  let Icon: ComponentType<any> = TableIcon;
  let backgroundColor: string | undefined = "gray.200";
  if (value.from?.type === MappingType.BOOSTED) {
    Icon = SparkleIconYellow;
    backgroundColor = undefined;
  }

  if (value.type === MappingType.STATIC) {
    Icon = NEW_STATIC_ICONS[value.valueType];
    backgroundColor = "forest.200";
  }

  if (value.type === MappingType.VARIABLE) {
    Icon = DollarIcon;
    backgroundColor = "warning.200";
  }

  if (value.type === MappingType.TEMPLATE) {
    Icon = ObjectIcon;
    backgroundColor = "ocean.200";
  }

  if (value.type === MappingType.OBJECT) {
    Icon = ObjectIcon;
    backgroundColor = undefined;
  }

  if (
    value.type === MappingType.ARRAY ||
    value.type === MappingType.ARRAY_FIELD
  ) {
    Icon = ArrayIcon;
    backgroundColor = undefined;
  }

  if (
    isEmpty(value) ||
    ((value.type === MappingType.STANDARD ||
      value.type === MappingType.FIELD) &&
      typeof value.from === "undefined") ||
    (value.type === MappingType.VARIABLE &&
      typeof value.variable === "undefined") ||
    (value.type === MappingType.STATIC && typeof value.value === "undefined") ||
    (value.type === MappingType.TEMPLATE &&
      typeof value.template === "undefined") ||
    (Object.keys(value).length === 1 && "to" in value)
  ) {
    return (
      <Box overflow="hidden">
        <Text color="gray.500" whiteSpace="nowrap">
          {placeholder}
        </Text>
      </Box>
    );
  }

  const isFieldBoosted = value.from?.type === MappingType.BOOSTED;
  const boostedFieldInModel = model?.columns?.find((col) => {
    return col.semantic_type === selectedOptionSemanticType;
  });

  const boostedColumnAlias = model?.syncable_columns.find((scc) => {
    return scc.column_reference.semanticType === selectedOptionSemanticType;
  });

  const formattedValue = formatValue(value);
  const component = (
    <Box
      alignItems="center"
      bg={backgroundColor}
      borderRadius="md"
      display="grid"
      gap={1}
      gridTemplateColumns="16px 1fr"
      height="100%"
      maxWidth="90%"
      px={2}
      sx={{
        span: {
          color: value.type === MappingType.OBJECT ? "forest.700" : undefined,
        },
      }}
    >
      {isTrait(value.from) || isCalculatedVisualColumnType(value.from?.type) ? (
        <Box as={TraitIcon} color="text.secondary" boxSize={4} ml={-0.5} />
      ) : (
        <Box as={Icon} boxSize={4} ml={-0.5} />
      )}

      <Text
        isTruncated
        size={
          value.type === MappingType.OBJECT || value.type === MappingType.ARRAY
            ? "md"
            : "sm"
        }
      >
        {isFieldBoosted
          ? boostedColumnAlias?.alias ?? formattedValue
          : formattedValue}{" "}
        {isFieldBoosted && boostedFieldInModel && (
          <Text color="text.secondary" isTruncated={true}>
            ({boostedFieldInModel.name})
          </Text>
        )}
      </Text>
    </Box>
  );

  if (!isFieldBoosted) {
    return component;
  } else if (
    isFieldBoosted &&
    boostedFieldInModel &&
    boostedColumnAlias?.alias
  ) {
    return (
      <Tooltip
        message={
          <Row display="inline" textAlign="left">
            Field has been boosted to send
            <Box pl={1} display="inline" pr={0.5}>
              <SparkleIcon color="warning.400" />
            </Box>
            <Text color="warning.400">{boostedColumnAlias.alias}</Text> data
            found in Hightouch's identity graph in addition to the data in the{" "}
            <Text color="text.tertiary">{boostedFieldInModel.name}</Text> column
            of the model.
          </Row>
        }
      >
        {component}
      </Tooltip>
    );
  }

  return (
    <Tooltip
      message={
        <Row display="inline">
          Field has been boosted to send
          <Box pl={1} display="inline" pr={0.5}>
            <SparkleIcon color="warning.400" />
          </Box>
          <Text color="warning.400">{boostedColumnAlias?.alias || ""}</Text>{" "}
          data found in Hightouch’s identity graph.
        </Row>
      }
    >
      {component}
    </Tooltip>
  );
};

function isTrait(value: unknown): boolean {
  return get(value, "column.type") === "trait";
}

const SparkleIconYellow = () => (
  <Box display="flex" fontSize="16px" ml={-0.5}>
    <SparkleIcon color="warning.400" />
  </Box>
);
