import { FC, useEffect, useState } from "react";

import {
  Column,
  FormField,
  Heading,
  Text,
  Textarea,
  TextInput,
} from "@hightouchio/ui";
import Helmet from "react-helmet";
import { useNavigate, useParams } from "src/router";

import { AudienceExplore } from "src/components/audiences/audience-explore";
import { Destinations } from "src/components/clone/destinations";
import { Form, useHightouchForm } from "src/components/form";
import { getTagsFromLabels, LabelForm } from "src/components/labels/label-form";
import { ResourceType } from "src/components/labels/use-labels";
import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  AudienceSplitsInsertInput,
  DraftOperation,
  useAudienceParentQuery,
  useAudienceQuery,
  useCreateAudienceMutation,
  useCreateAudienceSplitsMutation,
  useDestinationDefinitionsQuery,
  useSubmitDraftSyncMutation,
} from "src/graphql";
import { QueryType } from "src/types/models";
import { PageSpinner } from "src/components/loading";
import { DeprecatedWizard, WizardStep } from "src/components/wizard";
import { generateSlug } from "src/utils/slug";
import { useModelState } from "src/hooks/use-model-state";
import { useUser } from "src/contexts/user-context";

export const CloneAudience: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedSyncs, setSelectedSyncs] = useState<Set<string>>(new Set());
  const { workspace } = useUser();
  const approvalsRequired = workspace?.approvals_required;

  const { data: audienceData, isLoading: modelLoading } = useAudienceQuery(
    {
      id: String(id),
    },
    { enabled: Boolean(id) },
  );

  const audienceParentQuery = useAudienceParentQuery(
    {
      audience_id: String(id),
    },
    { enabled: Boolean(id), select: (data) => data.segments_by_pk?.parent },
  );

  const { isLoading: creatingAudience, mutateAsync: createAudience } =
    useCreateAudienceMutation();
  const { isLoading: creatingSplits, mutateAsync: createSplits } =
    useCreateAudienceSplitsMutation();
  const { isLoading: submittingDraftSync, mutateAsync: submitDraftSync } =
    useSubmitDraftSyncMutation();

  const audience = audienceData?.segments_by_pk;
  const parentModel = audienceParentQuery?.data;
  const source = parentModel?.connection;
  const syncs = audience?.syncs;

  const modelState = useModelState(audience);

  const {
    data: destinationDefinitions,
    isLoading: destinationDefinitionsLoading,
  } = useDestinationDefinitionsQuery(undefined, {
    select: (data) => data.getDestinationDefinitions,
  });

  const form = useHightouchForm({
    onSubmit: async ({ labels }) => {
      const syncsToClone = selectedSyncs.size
        ? syncs?.filter(({ id }) => selectedSyncs.has(String(id))) ?? []
        : [];

      // Associate the original sync ID to a newly generated slug.
      // We will assign these slugs to the cloned syncs.
      const oldSyncIdToNewSlug: Record<string, string> = {};
      syncsToClone.forEach((sync) => {
        oldSyncIdToNewSlug[sync.id] = generateSlug(
          `${name}-to-${sync.destination!.name}`,
        );
      });

      const result = await createAudience({
        input: {
          query_type: QueryType.Visual,
          visual_query_parent_id: parentModel?.id,
          visual_query_filter: modelState.state.visual_query_filter ?? {
            conditions: [],
          },
          name,
          description,
          primary_key: parentModel?.primary_key,
          connection_id: source?.id,
          tags: getTagsFromLabels(labels),
          destination_instances: {
            data: syncsToClone.map(
              ({
                id,
                destination,
                config,
                schedule,
                sync_alerts,
                sync_template_id,
                row_threshold_attempted,
                row_threshold_total,
              }) => ({
                destination_id: destination?.id,
                slug: oldSyncIdToNewSlug[id],
                draft: approvalsRequired,
                config: sync_template_id ? null : config,
                schedule: sync_template_id ? null : schedule,
                schedule_paused: true,
                row_threshold_attempted: sync_template_id
                  ? null
                  : row_threshold_attempted,
                row_threshold_total: sync_template_id
                  ? null
                  : row_threshold_total,
                sync_template_id,
                alert_instances: {
                  data: sync_alerts.map((alert) => ({
                    alert_id: alert.id,
                    fatal_error: alert.fatal_error,
                    row_error: alert.row_error,
                  })),
                },
              }),
            ),
          },
          subsets: modelState.state.subsets
            ? {
                data: modelState.state.subsets.map(
                  ({ subset_value: { id } }) => ({ subset_value_id: id }),
                ),
              }
            : undefined,
        },
      });

      if (approvalsRequired) {
        const clonedSyncIds: string[] = (
          result.insert_segments_one?.syncs ?? []
        ).map(({ id }) => id.toString());

        await Promise.all(
          clonedSyncIds.map(async (syncId: string) => {
            return submitDraftSync({
              resourceId: syncId,
              approverIds: [],
              operation: DraftOperation.Create,
              draft: {
                _set: {
                  draft: false,
                },
              },
            });
          }),
        );
      }

      if (audience?.splits.length) {
        // We have to clone splits separately because we join them against syncs.
        // Hasura can't handle the nested association in one call because we would be
        // creating syncs and splits together and then re-associating them.

        const newSyncSlugToNewId: Record<string, string> = {};
        result.insert_segments_one?.syncs.forEach((sync) => {
          newSyncSlugToNewId[sync.slug ?? ""] = sync.id;
        });

        const addSplits: AudienceSplitsInsertInput[] = audience.splits.map(
          (split) => {
            // We need to assign the cloned splits to the new syncs we just created.
            // In order to do that, we need to figure out the correct
            // mapping of old sync ID to new sync ID.
            // We do this by mapping from old sync ID -> new slug -> new sync ID.

            const assignedSyncIds = split.destination_instance_splits
              .map(({ destination_instance_id: oldSyncId }) => {
                const newSlug = oldSyncIdToNewSlug[oldSyncId] ?? "";
                return newSyncSlugToNewId[newSlug];
              })
              // Filter out nulls in case we couldn't map correctly or the sync wasn't cloned
              .filter((syncId) => Boolean(syncId));

            return {
              segment_id: result.insert_segments_one?.id,
              is_holdout_group: split.is_holdout_group,
              percentage: split.percentage,
              friendly_name: split.friendly_name,
              column_value: split.column_value,
              destination_instance_splits: {
                data: assignedSyncIds.map((syncId) => ({
                  destination_instance_id: syncId,
                })),
              },
            };
          },
        );

        await createSplits({ addSplits });
      }

      // TODO: add subsets

      navigate(`/audiences/${result.insert_segments_one?.id}`);
    },
    success: `Cloned ${audience?.name} and ${selectedSyncs?.size} syncs`,
    error: `"${audience?.name}" and ${selectedSyncs?.size} syncs could not be cloned.`,
    defaultValues: {
      labels: [{ key: "", value: "" }],
    },
  });

  useEffect(() => {
    if (audience) {
      modelState.set({ query_type: "visual", ...audience });
    }
    setName(audience?.name ?? "");
  }, [audience]);

  useEffect(() => {
    if (syncs) {
      setSelectedSyncs(new Set(syncs.map((sync) => String(sync.id))));
    }
  }, [syncs]);

  const { hasValidationErrors } = useFormErrorContext();

  if (
    modelLoading ||
    audienceParentQuery.isLoading ||
    destinationDefinitionsLoading
  ) {
    return <PageSpinner />;
  }

  const steps: WizardStep[] = [
    {
      title: "Change audience",
      render: () => (
        <AudienceExplore
          modelState={modelState}
          parentModel={parentModel}
          source={source}
        />
      ),
      onContinue: () => {
        if (!hasValidationErrors()) {
          setStep((s) => s + 1);
        }
      },
    },
  ];

  if (syncs?.length) {
    steps.push({
      title: "Select syncs",
      header: (
        <Column gap={2}>
          <Heading>Select destinations to clone associated syncs</Heading>
          <Text fontWeight="medium" color="text.secondary">
            New syncs to these destinations will be automatically created using
            the cloned model. These new syncs will be disabled by default.
          </Text>
        </Column>
      ),
      render: () => (
        <Destinations
          definitions={destinationDefinitions}
          selected={selectedSyncs}
          setSelected={setSelectedSyncs}
          syncs={syncs}
        />
      ),
    });
  }

  steps.push({
    title: "Finalize",
    disabled: !name,
    submitting: creatingAudience || creatingSplits || submittingDraftSync,
    header: <Heading>Finalize your settings</Heading>,
    render: () => (
      <Column gap={8}>
        <FormField label="Audience name">
          <TextInput
            placeholder={audience?.name}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </FormField>
        <FormField isOptional label="Description">
          <Textarea
            placeholder="Enter a description..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormField>
        <LabelForm
          heading="Add labels"
          hint="Example keys: team, project, region, env."
          resourceType={ResourceType.Model}
        />
      </Column>
    ),
  });

  return (
    <>
      <Helmet>
        <title>
          {audience?.name
            ? `Clone "${audience.name}" audience`
            : "Clone audience"}
        </title>
      </Helmet>

      <Form form={form}>
        <DeprecatedWizard
          fullscreen={step === 0}
          setStep={setStep}
          step={step}
          steps={steps}
          title="Clone audience"
          onCancel={() => {
            navigate(`/audiences/${id}`);
          }}
          onSubmit={form.submit}
        />
      </Form>
    </>
  );
};
