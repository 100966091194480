import { Alert, FormField, Combobox, TextInput } from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { isPresent } from "ts-extras";
import { FC } from "react";
import { capitalize } from "lodash";
import { DecisionEngineChannelConfig } from "@hightouch/lib/customer-data/decision-engine/types";

import {
  useDecisionEngineBaseEmailsQuery,
  useFormkitSyncHandlerQuery,
} from "src/graphql";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";
import { useOutletContext } from "src/router";
import { OutletContext } from "src/pages/decision-engines";

export const TemplateSelect: FC<
  Readonly<{
    destination: { type: string; id: string; definition: { name: string } };
    channel: { config: DecisionEngineChannelConfig };
  }>
> = ({ destination, channel }) => {
  const { engine } = useOutletContext<OutletContext>();
  const channelDefinition = getChannelDefinition(destination?.type);
  const campaignSetup = channelDefinition.campaignSetup(channel.config);
  const { data, error, isLoading } = useDecisionEngineBaseEmailsQuery(
    {
      destinationId: destination?.id,
    },
    {
      enabled: Boolean(destination),
      select: (data) => data.getEmailBaseMessages,
    },
  );
  const formkitQuery = useFormkitSyncHandlerQuery(
    {
      handler: "listObjects",
      variables: {
        type: "canvas",
      },
      context: {
        destinationId: Number(destination?.id),
        modelId: engine?.segment.id,
      },
    },
    {
      enabled: channel.config.type === "canvas",
      select: (data) => data.formkitSyncHandler,
    },
  );

  if (!destination) {
    return null;
  }

  if (error) {
    return (
      <Alert
        variant="inline"
        type="error"
        title="Error"
        message={error?.message}
      />
    );
  }

  if (campaignSetup === "freeform" || campaignSetup === "event-triggered") {
    return (
      <Controller
        name="config.baseMessageId"
        render={({ field }) => (
          <FormField
            label="Identifier"
            description="A unique identifier used to reference this message."
          >
            <TextInput {...field} placeholder="Identifier..." />
          </FormField>
        )}
      />
    );
  }

  if (campaignSetup === "template") {
    return (
      <Controller
        name="config.baseMessageId"
        render={({ field }) => (
          <FormField
            label="Template"
            description={`Choose a template that you’ve created in ${destination.definition.name}. If you haven’t created it yet, you can come back and select one later.`}
          >
            <Combobox
              isClearable
              width="100%"
              value={field.value?.toString()}
              onChange={(value) => {
                field.onChange(value ?? "");
              }}
              isLoading={isLoading || formkitQuery.isLoading}
              options={(data ?? []).filter(isPresent)}
              optionValue={(o) => String(o?.id)}
              optionLabel={(o) => String(o?.name)}
            />
          </FormField>
        )}
      />
    );
  }

  const resourceName = channelDefinition.getResourceName(channel.config);

  return (
    <Controller
      name="config.baseMessageId"
      render={({ field }) => (
        <FormField
          label={capitalize(resourceName)}
          description={`Choose a ${resourceName} that you’ve created in ${destination.definition.name}. If you haven’t created it yet, you can come back and select one later.`}
        >
          <Combobox
            isClearable
            width="100%"
            value={field.value?.toString()}
            onChange={(value) => {
              field.onChange(value ?? "");
            }}
            isLoading={isLoading || formkitQuery.isLoading}
            options={
              (
                (channel.config.type === "canvas" ? formkitQuery.data : data) ??
                []
              ).filter(isPresent) as any[]
            }
            optionValue={(o) =>
              channel.config.type === "canvas" ? o.value : String(o?.id)
            }
            optionLabel={(o) =>
              channel.config.type === "canvas" ? o.label : String(o?.name)
            }
          />
        </FormField>
      )}
    />
  );
};
