import { useState } from "react";

import { Outlet, useOutletContext } from "src/router";

import {
  SyncOp,
  useAttemptedRowsByPrimaryKeyQuery,
  useAttemptedRowsQuery,
} from "src/graphql";
import { getSyncAttemptDiff } from "src/utils/syncs";
import { RowsTable } from "./table";
import { Context } from "..";

const getRejectedAddedRows = (
  plannerType: string | undefined,
  syncError: unknown | undefined,
  syncRequest,
  diff,
) => {
  if (plannerType === "all") {
    // If there's an error, we treat all added rows as rejected rows
    return syncError ? syncRequest?.query_run?.size ?? 0 : 0;
  } else {
    return diff?.rejected?.add;
  }
};

export const SuccessfulRows = () => RowsPage(RowType.Successful);
export const RejectedRows = () => RowsPage(RowType.Rejected);

enum RowType {
  Successful = "successful",
  Rejected = "rejected",
}

const RowsPage = (rowType: RowType) => {
  const { sync, attempt } = useOutletContext<Context>();

  const [page, setPage] = useState<number>(0);
  const [pageKeys, setPageKeys] = useState<string[]>([]);

  const resetPagination = () => {
    setPageKeys([]);
    setPage(0);
  };

  const [searchInput, setSearchInput] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const [syncOpFilters, _setSyncOpFilters] = useState<Record<SyncOp, boolean>>({
    ADDED: true,
    CHANGED: true,
    REMOVED: true,
  });

  const setSyncOpFilters = (filters: Record<SyncOp, boolean>) => {
    resetPagination();
    _setSyncOpFilters(filters);
  };

  const showSuccessful = rowType === RowType.Successful;
  const showRejected = rowType === RowType.Rejected;

  const syncId = sync.id;
  const runId = attempt?.sync_request?.id;
  const model = sync?.segment;
  const source = model?.connection;

  const syncRequest = attempt?.sync_request;
  const syncError =
    syncRequest?.error ??
    (attempt?.error ? { message: attempt.error } : undefined);
  const primaryKey = syncRequest?.sync?.segment?.primary_key;
  const plannerType = syncRequest?.planner_type;
  const errorCodeDetail = syncRequest?.error_code_detail;

  if (syncError && errorCodeDetail) syncError.errorCodeDetail = errorCodeDetail;

  const diff = getSyncAttemptDiff(attempt);

  const queryRunSize = syncRequest?.query_run?.size ?? 0;

  const added =
    plannerType === "all"
      ? syncError
        ? 0
        : queryRunSize
      : (syncRequest?.add_executed ?? 0) - (diff?.rejected?.add ?? 0);

  const changed =
    (syncRequest?.change_executed ?? 0) - (diff?.rejected?.change ?? 0);
  const removed =
    (syncRequest?.remove_executed ?? 0) - (diff?.rejected?.remove ?? 0);

  const addedRows: number | undefined | null = showSuccessful
    ? added
    : getRejectedAddedRows(
        plannerType?.toString(),
        syncError,
        syncRequest,
        diff,
      );

  const changedRows: number | undefined | null = showSuccessful
    ? changed
    : diff?.rejected?.change;
  const removedRows: number | undefined | null = showSuccessful
    ? removed
    : diff?.rejected?.remove;

  let totalRows = 0;
  const opTypes: SyncOp[] = [];

  if (syncOpFilters.ADDED) {
    totalRows += addedRows ?? 0;
    opTypes.push(SyncOp.Added);
  }
  if (syncOpFilters.CHANGED && plannerType !== "all") {
    totalRows += changedRows ?? 0;
    opTypes.push(SyncOp.Changed);
  }
  if (syncOpFilters.REMOVED && plannerType !== "all") {
    totalRows += removedRows ?? 0;
    opTypes.push(SyncOp.Removed);
  }

  const limit = 10;
  const pages = Math.ceil(totalRows / limit);

  const {
    data: attemptedRowsData,
    isLoading: attemptedRowsLoading,
    isRefetching: attemptedRowsRefetching,
    error: attemptedRowsQueryError,
  } = useAttemptedRowsQuery(
    {
      destinationInstanceId: Number(syncId),
      syncRequestId: Number(runId),
      onlyRejected: showRejected,
      onlySuccessful: showSuccessful,
      pageKey: pageKeys.slice(-1)[0],
      opTypes,
      limit,
      plannerType: String(plannerType),
    },
    {
      // Only run once we have a response from the SyncAttempt query,
      // otherwise this will run twice: once on initial load, and once after the attempt is loaded.
      // Also, don't run this query if we are not on tab that has rows (eg. on config or pending tabs).
      enabled:
        Boolean(attempt) &&
        !(plannerType === "all" && totalRows === 0) &&
        Object.values(syncOpFilters).some((filter) => filter),
    },
  );

  const {
    data: attemptedRowsByPKData,
    isLoading: attemptedRowsByPKLoading,
    isRefetching: attemptedRowsByPKRefetching,
  } = useAttemptedRowsByPrimaryKeyQuery(
    {
      destinationInstanceId: Number(syncId),
      id: search,
      onlyRejected: showRejected,
      onlySuccessful: showSuccessful,
      plannerType: String(plannerType),
      syncRequestId: Number(runId),
    },
    {
      enabled: Boolean(search) && plannerType !== "all",
      keepPreviousData: true,
    },
  );

  return (
    <>
      <RowsTable
        addedRows={addedRows}
        attemptedRowsByPKData={attemptedRowsByPKData}
        attemptedRowsByPKLoading={
          attemptedRowsByPKLoading || attemptedRowsByPKRefetching
        }
        attemptedRowsData={attemptedRowsData}
        attemptedRowsLoading={attemptedRowsLoading || attemptedRowsRefetching}
        attemptedRowsQueryError={attemptedRowsQueryError}
        changedRows={changedRows}
        page={page}
        pages={pages}
        plannerType={plannerType}
        primaryKey={primaryKey}
        removedRows={removedRows}
        search={search}
        searchInput={searchInput}
        setPage={setPage}
        setPageKeys={setPageKeys}
        setSearch={setSearch}
        setSearchInput={setSearchInput}
        setSyncOpFilters={setSyncOpFilters}
        syncOpFilters={syncOpFilters}
        showRejected={showRejected}
        source={source}
        sync={sync}
        syncError={syncError}
        syncRequest={syncRequest}
      />
      {/* Required to render debugger */}
      <Outlet />
    </>
  );
};
