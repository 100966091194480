import { DecisionEngineChannelType } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  Box,
  Column,
  EyeIcon,
  ObjectIcon,
  Row,
  SparkleIcon,
  ToggleButton,
  ToggleButtonGroup,
  Text,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

import { useOutletContext } from "src/router";
import { Card } from "src/components/card";
import { useUser } from "src/contexts/user-context";
import { useDecisionEngineEmailPreviewQuery } from "src/graphql";
import { OutletContext } from "src/pages/decision-engines";
import { ChannelIcon } from "src/pages/decision-engines/components/channel-icon";
import { EmailPreview } from "src/pages/decision-engines/components/email-preview";
import { useMessagePreview } from "src/pages/decision-engines/utils";
import { formatDatetime } from "src/utils/time";
import { InteractionRow } from "./messages";
import { Reasoning } from "./reasoning";
import { Editor } from "src/components/editor";

export const Interaction = ({
  message,
  interaction,
  error,
  usersByPrimaryKey,
}: {
  message: {
    config: {
      campaignId: string;
    };
    message: {
      name: string;
    };
  };
  usersByPrimaryKey: Record<string, any>;
  interaction: InteractionRow;
  error: string | undefined;
}) => {
  const { decisionEngineDemo } = useFlags();
  const { workspace, user: hightouchUser } = useUser();
  const { engine, messages } = useOutletContext<OutletContext>();

  // TODO: Possible we don't have the user fetched yet, will need to query for this separately
  const user = usersByPrimaryKey[interaction.primary_key];
  const channel = messages.find(
    (m) => m.id === interaction.action_features.message,
  )!.channel;

  const { run, previewId, isPreviewable } = useMessagePreview({
    channel: channel!,
  });
  const [shouldPoll, setShouldPoll] = useState(false);
  const [mode, setMode] = useState<
    "preview" | "data" | "reasoning" | "internal"
  >(isPreviewable ? "preview" : "data");

  const { data: emailBody, error: previewError } =
    useDecisionEngineEmailPreviewQuery(
      {
        previewId: previewId ?? "",
      },
      {
        enabled: isPreviewable && Boolean(previewId),
        refetchInterval: shouldPoll ? 1000 : undefined,
        select: (data) => data.getEmailPreview,
        keepPreviousData: true,
        onSettled: (data, error) => {
          if (data || error) {
            // Stop polling after getting the preview
            setShouldPoll(false);
          }
        },
      },
    );

  const loadPreview = async () => {
    const { variables, ...items } = interaction.sync_metadata;
    const success = await run({
      segment: engine.segment,
      workspaceId: workspace?.id,
      campaignId: message!.config.campaignId,
      variables,
      items,
      user,
    });
    if (success) {
      setShouldPoll(true);
    }
  };

  useEffect(() => {
    loadPreview();
  }, [interaction.interaction_id]);

  return (
    <>
      <Column pb={4} px={6}>
        {isPreviewable && (
          <Row align="center">
            <ToggleButtonGroup
              value={mode}
              onChange={(value: any) => setMode(value)}
            >
              <ToggleButton
                label={
                  <Row gap={2}>
                    <Box as={EyeIcon} boxSize={5} color="text.secondary" />
                    <Text>Preview</Text>
                  </Row>
                }
                value="preview"
              />
              <ToggleButton
                label={
                  <Row gap={2}>
                    <Box as={ObjectIcon} boxSize={5} color="text.secondary" />
                    <Text>Data</Text>
                  </Row>
                }
                value="data"
              />
              {decisionEngineDemo && (
                <ToggleButton
                  label={
                    <Row gap={2}>
                      <Box
                        as={SparkleIcon}
                        boxSize={5}
                        color="text.secondary"
                      />
                      <Text>Reasoning</Text>
                    </Row>
                  }
                  value="reasoning"
                />
              )}
              {hightouchUser?.can_impersonate && (
                <ToggleButton label="Internal" value="internal" />
              )}
            </ToggleButtonGroup>
          </Row>
        )}
      </Column>

      {mode === "preview" ? (
        <Column p={6} pt={0} overflow="hidden" flex={1}>
          <EmailPreview
            subject={interaction.sync_metadata.variables?.subject}
            error={previewError?.message || error}
            isLoading={shouldPoll}
            body={emailBody}
            placeholder={
              <Text color="text.secondary" size="lg">
                No preview available
              </Text>
            }
          />
        </Column>
      ) : mode === "data" ? (
        <Column p={6} pt={0} overflow="hidden" flex={1}>
          <Card p={0} overflow="hidden">
            <Editor
              readOnly
              value={JSON.stringify(
                formatInteraction(user, interaction),
                null,
                2,
              )}
              language="json"
            />
          </Card>
        </Column>
      ) : mode === "internal" ? (
        <Column p={6} pt={0} overflow="hidden" flex={1}>
          <Card p={0} overflow="hidden">
            <Editor
              readOnly
              value={JSON.stringify(interaction, null, 2)}
              language="json"
            />
          </Card>
        </Column>
      ) : (
        <Column p={6} pt={0} overflow="auto">
          <Reasoning
            interaction={interaction}
            message={message!.message}
            channel={channel!}
          />
        </Column>
      )}
    </>
  );
};

const formatInteraction = (user: any, interaction: InteractionRow) => {
  return {
    hightouch: {
      user,
      recommendation: interaction.sync_metadata,
    },
  };
};

export const InteractionHeader = ({ interaction, message }) => {
  const { engine } = useOutletContext<OutletContext>();

  const channel = engine.channels.find(
    (channel) => channel.id === interaction.action_features.channel,
  );
  // Old messages use the type instead of the id
  const channelType = (channel?.type ||
    interaction.action_features.channel) as DecisionEngineChannelType;

  return (
    <Row gap={2} align="center" h="36px">
      <ChannelIcon size={5} type={channelType} />
      <Column>
        <Text fontWeight="medium">{message?.message.name}</Text>
        <Text color="text.secondary" size="sm">
          {formatDatetime(new Date(interaction.send_at).toISOString())}
        </Text>
      </Column>
    </Row>
  );
};
