import { FC, Fragment, useMemo, useState } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  Column,
  ConfirmationDialog,
  EditIcon,
  Menu,
  MenuActionsButton,
  MenuButton,
  MenuDivider,
  MenuIconButton,
  MenuItem,
  MenuList,
  PlusIcon,
  Row,
  SectionHeading,
  Spinner,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import pluralize from "pluralize";
import { useFormContext } from "react-hook-form";
import * as y from "yup";
import { assertedConditionType } from "@hightouch/lib/resource-monitoring/assertions";
import {
  ChannelType,
  ConditionFriendlyNames,
  EventSourceVolumeInterval,
  MonitorConditionEvaluationProperties,
  MonitorConditionTypes,
  MonitorStatus,
  MonitoredResourceType,
  ParentResourceTypes,
  SupportedConditionsForResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import { differenceBy, isEqual, orderBy, uniqBy } from "lodash";

import notificationsPlaceholder from "src/assets/placeholders/notifications.svg";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  InsertDefaultMonitorConditionsMutationVariables,
  SubscribeableResourcesQuery,
  useCreateNotificationTemplateChannelsMutation,
  useDeleteAllTriggerOverridesMutation,
  useDeleteNotificationChannelTemplatesByDestinationMutation,
  useDeleteResourceNotificationChannelsByDestinationMutation,
  useEventDestinationsQuery,
  useInsertDefaultMonitorConditionsMutation,
  useOverrideNotificationChannelTemplatesMutation,
  useResourceTypeMonitorConditionsQuery,
  useSubscribeableResourcesQuery,
} from "src/graphql";
import { Link, useNavigate, useSearchParams } from "src/router";
import {
  BulkEditableMonitorConditionTypes,
  BulkEditableResourceTypes,
  MonitorConditionForm,
  defaultValueForSyncConditionType,
  valueIsEmpty,
} from "./monitor-condition-forms/monitor-condition-form";
import { SubscribedResourceItem } from "src/components/notification-channels/subscribed-resource-item";
import { EventWarehouseDestinationType } from "src/events/types";
import { enumOrFallback, isPresent } from "src/types/utils";
import type { BulkManageMonitorConditionFormState } from "./types";
import { useOverridesState } from "./use-overrides-state";
import { isSupportedConditionForResourceType } from "./utils";
import { channelName } from "src/components/notification-channels/channel-definitions";
import { ChannelIcon } from "src/pages/alerting/recipients/components/channel-icon";
import { Recipient, useRecipients } from "src/pages/alerting/utils";

const DestinationRowDecoration: FC<{
  monitorConditionType: MonitorConditionTypes;
  destination: SubscribeableResourcesQuery["destinations"][0];
  overridingSyncsCount: number;
}> = ({ destination, overridingSyncsCount, monitorConditionType }) => {
  const [searchParams, _] = useSearchParams();
  const overrideParams = new URLSearchParams(searchParams);
  overrideParams.set("forDestinationId", destination.id);
  overrideParams.set("forMonitorConditionType", monitorConditionType);

  return (
    <>
      <IntegrationIcon
        name={destination.name || destination.definition.name}
        src={destination.definition.icon}
      />
      <Column>
        <Text fontWeight="medium">
          {destination.name || destination.definition.name}
        </Text>
        <Text size="md" color="gray.700">
          {destination.syncs_aggregate.aggregate?.count}{" "}
          {pluralize("sync", destination.syncs_aggregate.aggregate?.count)}
          {overridingSyncsCount > 0 && (
            <>
              {" "}
              •{" "}
              <Link href={`overrides?${overrideParams.toString()}`}>
                {`${overridingSyncsCount} sync-level ${pluralize(
                  "override",
                  overridingSyncsCount,
                )}`}
              </Link>
            </>
          )}
        </Text>
      </Column>
    </>
  );
};

const ConditionRowHeading: FC<{
  conditionType: BulkEditableMonitorConditionTypes;
  resourceType: BulkEditableResourceTypes;
  setBulkEditingConditionType: (
    conditionType: MonitorConditionTypes | null,
  ) => void;
  bulkEditingConditionType: MonitorConditionTypes | null;
  destinationCount: number;
}> = ({
  conditionType,
  resourceType,
  setBulkEditingConditionType,
  bulkEditingConditionType,
  destinationCount,
}) => {
  const { handleSubmit, setValue } =
    useFormContext<BulkManageMonitorConditionFormState>();

  const submit = async (data: BulkManageMonitorConditionFormState) => {
    const values = data[conditionType];

    const enabled = values.bulk?.enabled ?? false;
    const errorValue = values.bulk?.error_value;
    const warningValue = values.bulk?.warning_value;

    // Only merge in bulk values if something has changed.
    // If we blindly merge values.bulk sometimes the form will be left in an unexpected state.
    const updates = {
      enabled,
      ...(valueIsEmpty(conditionType, errorValue)
        ? {}
        : { error_value: errorValue }),
      ...(valueIsEmpty(conditionType, warningValue)
        ? {}
        : { warning_value: warningValue }),
    };

    setValue<any>(
      `${conditionType}`,
      {
        destinations: Object.entries(values.destinations).reduce(
          (destinations, [id, destination]) => ({
            ...destinations,
            [id]: {
              ...destination,
              ...updates,
            },
          }),
          {},
        ),
      },
      {
        shouldDirty: true,
      },
    );
    setBulkEditingConditionType(null);
  };

  const isEditing = bulkEditingConditionType === conditionType;

  const sx = {
    bg: "base.lightBackground",
    p: 4,
  };

  if (isEditing) {
    return (
      <MonitorConditionForm
        sx={sx}
        title={
          <Text fontWeight="medium">
            {ConditionFriendlyNames[conditionType]}
          </Text>
        }
        action={
          <ButtonGroup>
            <Button
              variant="primary"
              size="sm"
              type="submit"
              onClick={handleSubmit(submit)}
            >
              Apply to {destinationCount}{" "}
              {pluralize("destination", destinationCount)}
            </Button>
            <Button
              size="sm"
              onClick={() => {
                setBulkEditingConditionType(null);
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        }
        name="bulk"
        conditionType={conditionType}
        resourceType={resourceType}
      />
    );
  }

  return (
    <>
      <Row sx={sx} gap={2}>
        <Text fontWeight="medium">{ConditionFriendlyNames[conditionType]}</Text>
      </Row>
      <Box sx={sx} gridColumn="span 2">
        {destinationCount > 1 ? (
          <Button
            icon={EditIcon}
            size="sm"
            onClick={() => {
              setBulkEditingConditionType(conditionType);
            }}
          >
            Bulk edit {destinationCount}{" "}
            {pluralize("destination", destinationCount)}
          </Button>
        ) : (
          <Box />
        )}
      </Box>
    </>
  );
};

export const ResourceMonitoringTextButtonStyles = {
  sx: {
    _hover: {
      color: "link.hover",
    },
  },
  color: "link.default",
  cursor: "pointer",
  fontWeight: "medium" as const,
};

export const BulkDestinationAlertTriggerManagement: FC<{
  destinationIds: Array<string>;
}> = ({ destinationIds }) => {
  const [bulkEditingConditionType, setBulkEditingConditionType] =
    useState<MonitorConditionTypes | null>(null);

  const resourceType = MonitoredResourceType.Sync as const; // Get this from search when we add new resource types: search.get("resourceType");

  const { data } = useResourceTypeMonitorConditionsQuery(
    {
      resourceType: MonitoredResourceType.Sync,
      parentResourceType: ParentResourceTypes.Destination,
      includeTemplates: true,
    },
    { suspense: true },
  );

  const { data: syncsAndDestinations } = useSubscribeableResourcesQuery(
    undefined,
    { suspense: true },
  );

  const { data: eventSyncDestinations } = useEventDestinationsQuery(
    { forwardingTypes: [] },
    {
      suspense: true,
      select: (data) =>
        data.event_warehouse_destinations
          .map((destination) => {
            const destinationType = enumOrFallback(
              EventWarehouseDestinationType,
              undefined,
              false,
            )(destination.type);

            return destinationType
              ? {
                  ...destination,
                  type: destinationType,
                }
              : undefined;
          })
          .filter(isPresent),
    },
  );

  const { mutateAsync: createDefaultMonitorConditions } =
    useInsertDefaultMonitorConditionsMutation();

  const destinations = useMemo(
    () =>
      resourceType === MonitoredResourceType.Sync
        ? syncsAndDestinations?.destinations.map((destination) => ({
            ...destination,
            option: {
              label: destination.name || destination.definition.name,
              value: String(destination.id.toString()),
              icon: destination.definition.icon,
            },
          })) || []
        : [],
    [resourceType, syncsAndDestinations, eventSyncDestinations],
  );

  const filteredDestinations = useMemo(
    () =>
      destinations.filter(
        (destination) => destinationIds?.includes(destination?.id.toString()),
      ) || [],
    [syncsAndDestinations, destinationIds],
  );

  const { getOverrideCount } = useOverridesState(destinationIds);

  const monitorConditionFormDefaults = useMemo(() => {
    const defaults: BulkManageMonitorConditionFormState = {
      [MonitorConditionTypes.SyncSequentialFailures]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncRejectedRows]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncDuration]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncMissedSchedules]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncThroughput]: {
        destinations: {},
      },
      [MonitorConditionTypes.ModelSize]: {
        destinations: {},
      },
    };

    for (const destination of filteredDestinations) {
      if (!data) continue;
      const existingDestinationSyncConditions =
        data.monitor_condition_templates?.filter(
          (condition) =>
            condition.parent_resource_id === destination.id.toString() &&
            isSupportedConditionForResourceType(resourceType, condition.type),
        ) || [];

      const destinationId = destination.id.toString();

      for (const conditionType of SupportedConditionsForResourceTypes[
        resourceType
      ]) {
        defaults[conditionType]["destinations"][destinationId] = {
          warning_value: null,
          error_value: defaultValueForSyncConditionType[conditionType],
          enabled: false,
          parent_resource_id: destinationId,
          parent_resource_type: ParentResourceTypes.Destination,
        };
      }

      if (!existingDestinationSyncConditions.length) continue;

      for (const condition of existingDestinationSyncConditions) {
        defaults[assertedConditionType(condition.type)]["destinations"][
          destinationId
        ] = {
          warning_value: condition.warning_value,
          enabled: condition.enabled,
          error_value: condition.error_value,
          parent_resource_id: condition.parent_resource_id,
          parent_resource_type: condition.parent_resource_type,
        };
      }
    }
    return defaults;
  }, [data, filteredDestinations]);

  const form = useHightouchForm({
    reValidateMode: "onSubmit",
    resolver: yupResolver(monitorConditionSchema(true)),
    errorMessage: "See errors below.",
    values: monitorConditionFormDefaults,
    onSubmit: async (data) => {
      const args: InsertDefaultMonitorConditionsMutationVariables["objects"] =
        [];

      for (const [conditionType, state] of Object.entries(data)) {
        for (const [destinationId, condition] of Object.entries(
          state.destinations,
        )) {
          if (condition.enabled !== undefined) {
            const ConditionType = assertedConditionType(conditionType);
            args.push({
              type: ConditionType,
              parent_resource_id: destinationId,
              parent_resource_type: ParentResourceTypes.Destination,
              error_value: condition.error_value,
              warning_value: condition.warning_value,
              enabled: condition.enabled,
              evaluation_trigger:
                MonitorConditionEvaluationProperties[ConditionType]
                  .EvaluationTrigger,
              evaluation_type:
                MonitorConditionEvaluationProperties[ConditionType]
                  .EvaluationType,
            });
          }
        }
      }
      await createDefaultMonitorConditions({ objects: args });
    },
  });

  const selectedDestinations = useMemo(() => {
    return destinationIds
      .map((id) => destinations.find((d) => d.id.toString() === id))
      .filter(isPresent);
  }, [destinationIds, destinations]);

  const syncIds =
    syncsAndDestinations?.syncs
      .filter((sync) =>
        destinationIds.includes(sync.destination?.id?.toString()),
      )
      .map((sync) => sync.id?.toString()) ?? [];

  return (
    <Form form={form}>
      <Column flex={1}>
        <Heading destinations={selectedDestinations} syncIds={syncIds} />
        <Recipients destinationIds={destinationIds} />
        {filteredDestinations.length > 0 && resourceType && (
          <BulkManagementTable>
            {SupportedConditionsForResourceTypes[resourceType].map(
              (conditionType) => {
                return (
                  <Fragment key={conditionType}>
                    <ConditionRowHeading
                      conditionType={conditionType}
                      resourceType={resourceType}
                      setBulkEditingConditionType={setBulkEditingConditionType}
                      bulkEditingConditionType={bulkEditingConditionType}
                      destinationCount={filteredDestinations.length}
                    />
                    {filteredDestinations.map((destination, idx) => {
                      return (
                        <MonitorConditionForm
                          isObscured={
                            bulkEditingConditionType === conditionType
                          }
                          conditionType={conditionType}
                          resourceType={resourceType}
                          title={
                            <DestinationRowDecoration
                              monitorConditionType={conditionType}
                              overridingSyncsCount={getOverrideCount(
                                conditionType,
                                [destination.id.toString()],
                              )}
                              destination={destination}
                            />
                          }
                          key={`${idx}-${destination.id}`}
                          name={`destinations.${destination.id}`}
                        />
                      );
                    })}
                  </Fragment>
                );
              },
            )}
          </BulkManagementTable>
        )}
        <ActionBar fit>
          <FormActions
            confirmation={{
              title: "Confirm changes",
              message:
                "Updating default triggers will affect the alerting configuration for the syncs associated with the selected destinations.",
            }}
          />
        </ActionBar>
      </Column>
    </Form>
  );
};

const conditionSchema = (
  bulk: boolean,
  valueSchema: y.ObjectSchema | y.NumberSchema,
) =>
  y.lazy((value: any) => {
    if (value) {
      const schema = y
        .object()
        .shape({
          enabled: y.boolean(),
          error_value: valueSchema.nullable().optional(),
          warning_value: valueSchema.nullable().optional(),
        })
        .required();
      if (bulk) {
        return y.object().shape({
          destinations: y.object().shape(
            Object.keys(value.destinations).reduce(
              (acc, destinationId) => ({
                ...acc,
                [destinationId]: schema,
              }),
              {},
            ),
          ),
        });
      } else {
        return schema;
      }
    }
    return y.object();
  });

export const monitorConditionSchema = (
  bulk: boolean,
): y.ObjectSchema<
  y.Shape<
    object | undefined,
    {
      [k in BulkEditableMonitorConditionTypes]: y.Lazy;
    }
  >,
  object
> =>
  y.object().shape({
    [MonitorConditionTypes.SyncRejectedRows]: conditionSchema(
      bulk,
      y.object().shape({
        pctRejectedRows: y.number().min(0).max(100).nullable().optional(),
        rejectedRowCount: y.number().min(0).nullable().optional(),
      }),
    ),
    [MonitorConditionTypes.SyncDuration]: conditionSchema(
      bulk,
      y.number().min(10).max(1440),
    ),
    [MonitorConditionTypes.SyncMissedSchedules]: conditionSchema(
      bulk,
      y.number().min(10).max(1440),
    ),
    [MonitorConditionTypes.SyncSequentialFailures]: conditionSchema(
      bulk,
      y.number().min(0).max(100),
    ),
    [MonitorConditionTypes.SyncThroughput]: conditionSchema(
      bulk,
      y.object().shape({
        count: y.number().min(0).max(1e8),
        sign: y.string().oneOf(["gt", "lt"]),
        interval: y
          .string()
          .oneOf(["7 days", "2 days", "1 day", "4 hours", "1 hour"]),
      }),
    ),
    [MonitorConditionTypes.EventSourceVolume]: conditionSchema(
      bulk,
      y.object().shape({
        sign: y.string().oneOf(["gt", "lt", "gtlt"]).required(),
        interval: y.string().oneOf(EventSourceVolumeInterval).required(),
        count: y
          .number()
          .min(0)
          .max(1e8)
          .when("sign", {
            is: "gtlt",
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.required(),
          }),
        min: y
          .number()
          .min(0)
          .max(1e8)
          .when("sign", {
            is: "gtlt",
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
        max: y
          .number()
          .min(0)
          .max(1e8)
          .moreThan(y.ref("min"), "x events must be greater than min")
          .when("sign", {
            is: "gtlt",
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
      }),
    ),
    [MonitorConditionTypes.ModelSize]: conditionSchema(
      bulk,
      y.array(
        y.object().shape({
          sign: y.string().oneOf(["gt", "lt"]).required(),
          count: y.number().min(0).max(1e8),
        }),
        // We need to use typebox yup is such a pain to fix
      ) as unknown as y.ObjectSchema,
    ),
  });

export const BulkManagementTable = ({ children }) => {
  return (
    <Box display="grid" gridTemplateColumns="1fr 2fr 1fr" overflow="auto">
      <Box
        p={4}
        pl={6}
        textTransform="uppercase"
        fontWeight="semibold"
        color="text.secondary"
        borderBottom="1px"
        borderColor="base.border"
      >
        Destination
      </Box>
      <Box
        p={4}
        textTransform="uppercase"
        fontWeight="semibold"
        color="text.secondary"
        borderBottom="1px"
        borderColor="base.border"
      >
        Trigger
      </Box>
      <Box
        p={4}
        textTransform="uppercase"
        fontWeight="semibold"
        color="text.secondary"
        borderBottom="1px"
        borderColor="base.border"
      >
        Action
      </Box>
      {children}
    </Box>
  );
};

const Heading = ({
  syncIds,
  destinations,
}: {
  syncIds: string[];
  destinations: Array<{
    id: string;
    name: string | null;
    definition: {
      icon: string;
      name: string;
    };
  }>;
}) => {
  const destinationIds = destinations.map((d) => d.id.toString());
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutateAsync: removeSyncOverrides } =
    useDeleteAllTriggerOverridesMutation();

  const { getSyncCount, getOverrideCount } = useOverridesState(destinationIds);

  if (!destinations.length) return null;

  const singleDestination = (
    <Row gap={2} align="center">
      <IntegrationIcon
        src={destinations[0]!.definition.icon}
        name={destinations[0]!.name || destinations[0]!.definition.name}
      />
      <SectionHeading>
        {destinations[0]!.name || destinations[0]!.definition.name}
      </SectionHeading>
    </Row>
  );

  const multiDestination = (
    <Row gap={2} flexWrap="wrap">
      {destinations.map(({ id, name, definition }) => (
        <Tag key={id} imageUrl={definition.icon}>
          {name || definition.name}
        </Tag>
      ))}
    </Row>
  );

  const overrideCount = getOverrideCount(
    MonitorConditionTypes.SyncSequentialFailures,
    destinationIds,
  );

  const syncCount = getSyncCount(destinationIds);

  return (
    <Row
      px={6}
      py={4}
      borderBottom="1px"
      borderColor="base.border"
      justify="space-between"
      align="center"
    >
      {destinations.length === 1 ? singleDestination : multiDestination}
      <Row gap={4} align="center">
        <Text color="text.secondary">
          {`${syncCount} ${pluralize("sync", syncCount)}`}
          {overrideCount
            ? ` (${overrideCount} ${pluralize(
                "sync",
                overrideCount,
              )} using overrides)`
            : ""}
        </Text>
        <Menu>
          <MenuActionsButton variant="secondary" />
          <MenuList>
            <MenuItem variant="danger" onClick={onOpen}>
              Clear all sync-level overrides
            </MenuItem>
          </MenuList>
        </Menu>
      </Row>

      <ConfirmationDialog
        isOpen={isOpen}
        title="Clear overrides"
        confirmButtonText="Proceed"
        variant="warning"
        onClose={onClose}
        onConfirm={async () => {
          await removeSyncOverrides({
            resourceIds: syncIds ?? [],
            resourceType: MonitoredResourceType.Sync,
          });
        }}
      >
        Are you sure you want to clear {overrideCount} sync-level{" "}
        {pluralize("override", overrideCount)}?
      </ConfirmationDialog>
    </Row>
  );
};

export const Recipients = ({
  destinationIds,
}: {
  destinationIds: string[];
}) => {
  const { toast } = useToast();
  const { getRecipientsByDestination, getRecipientsForDestinations } =
    useRecipients();

  const { mutateAsync: deleteNotficationTemplates } =
    useDeleteNotificationChannelTemplatesByDestinationMutation();
  const { mutateAsync: deleteNotifications } =
    useDeleteResourceNotificationChannelsByDestinationMutation();

  const recipients = getRecipientsForDestinations(destinationIds);
  const recipientsByDestination = getRecipientsByDestination(destinationIds);

  // Conflict if either destinations have mismatched recipients or if a destination has none
  const isConflicting = Object.values(recipientsByDestination).some(
    (destinationRecipients: any) =>
      !isEqual(
        recipients.map((r) => r.channel.id),
        destinationRecipients.map((r) => r.channel.id),
      ),
  );

  return (
    <Column p={6} borderBottom="1px" borderColor="base.border" gap={2}>
      <Column>
        <SectionHeading>Recipients</SectionHeading>
        {!isConflicting && recipients.length > 0 && (
          <Text color="text.secondary">
            Send all alerts to the following recipients:
          </Text>
        )}
      </Column>
      {isConflicting ? (
        <RecipientConflict destinationIds={destinationIds} />
      ) : recipients.length === 0 ? (
        <NoRecipients destinationIds={destinationIds} recipients={recipients} />
      ) : (
        <>
          <Row gap={2} flexWrap="wrap">
            {recipients.map((r) => (
              <SubscribedResourceItem
                key={r.id}
                channel={r.channel}
                onRemove={async () => {
                  if (r.__typename === "notification_channel_templates") {
                    await deleteNotficationTemplates({
                      channelId: r.channel.id,
                      destinationIds,
                    });
                  } else {
                    await deleteNotifications({
                      channelId: r.channel.id,
                      destinationIds,
                    });
                  }
                  toast({
                    id: "remove-recipient",
                    title: "Removed recipient",
                    variant: "success",
                  });
                }}
              />
            ))}
            <AddRecipientButton
              isIcon
              destinationIds={destinationIds}
              recipients={recipients}
              isOverriding={false}
            />
          </Row>
        </>
      )}
    </Column>
  );
};

const NoRecipients = ({
  destinationIds,
  recipients,
}: {
  destinationIds: string[];
  recipients: Array<Recipient>;
}) => {
  return (
    <Column gap={4} align="center">
      <Box as="img" src={notificationsPlaceholder} boxSize={24} />
      <Column align="center">
        <Text size="lg" color="text.secondary" fontWeight="medium">
          Add recipients to receive alerts via Slack, PagerDuty, SMS, or email
        </Text>
        <Text color="text.secondary">
          You will only see alerts in Hightouch if you don’t add any recipients.
        </Text>
      </Column>
      <AddRecipientButton
        isOverriding={false}
        destinationIds={destinationIds}
        recipients={recipients}
      />
    </Column>
  );
};

const RecipientConflict = ({
  destinationIds,
}: {
  destinationIds: string[];
}) => {
  return (
    <Column gap={4} align="center">
      <Box as="img" src={notificationsPlaceholder} boxSize={24} />
      <Column align="center">
        <Text size="lg" color="text.secondary" fontWeight="medium">
          These destinations have conflicting recipients
        </Text>
        <Text color="text.secondary">
          Adding a recipient will override the existing recipients
        </Text>
      </Column>
      <AddRecipientButton
        isOverriding
        destinationIds={destinationIds}
        recipients={[]}
      />
    </Column>
  );
};

const AddRecipientButton = ({
  destinationIds,
  recipients,
  isIcon = false,
  isOverriding,
}: {
  destinationIds: string[];
  recipients: Array<Recipient>;
  isOverriding: boolean;
  isIcon?: boolean;
}) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { data } = useRecipients();

  const allRecipients = uniqBy(
    [
      ...data!.notification_channel_templates,
      ...data!.resource_notification_channels,
    ],
    "channel.id",
  ) as Array<Recipient>;

  const unusedRecipients = orderBy(
    differenceBy(allRecipients, recipients, "channel.id"),
    "channel.channel_type",
  ) as Array<Recipient>;

  const onAdd = () => {
    navigate({
      pathname: "/alerting/recipients/new",
      search: `?destinations=${destinationIds.join(",")}`,
    });
  };

  const AddMenuItem = ({ recipient }: { recipient: Recipient }) => {
    const { mutateAsync: createNotificationTemplateChannels, isLoading } =
      useCreateNotificationTemplateChannelsMutation();

    const addRecipient = async () => {
      await createNotificationTemplateChannels({
        objects: destinationIds.map((id) => ({
          channel_id: recipient.channel.id,
          parent_resource_id: id,
          parent_resource_type: ParentResourceTypes.Destination,
          status: MonitorStatus.Unhealthy,
        })),
      });

      toast({
        id: "add-recipient",
        title: "Successfully added recipient",
        variant: "success",
      });
    };

    return (
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          addRecipient();
        }}
      >
        <Row align="center" gap={4}>
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <ChannelIcon
              channelType={recipient.channel.channel_type as ChannelType}
            />
          )}
          {channelName(recipient.channel)}
        </Row>
      </MenuItem>
    );
  };

  const OverrideMenuItem = ({ recipient }: { recipient: Recipient }) => {
    const { mutateAsync: overrideNotificationChannelTemplates, isLoading } =
      useOverrideNotificationChannelTemplatesMutation();

    const overrideRecipients = async () => {
      await overrideNotificationChannelTemplates({
        resourceIds: destinationIds,
        objects: destinationIds.map((id) => ({
          channel_id: recipient.channel.id,
          parent_resource_id: id,
          parent_resource_type: ParentResourceTypes.Destination,
          status: MonitorStatus.Unhealthy,
        })),
      });
    };

    return (
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          overrideRecipients();
        }}
      >
        <Row align="center" gap={4}>
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <ChannelIcon
              channelType={recipient.channel.channel_type as ChannelType}
            />
          )}
          {channelName(recipient.channel)}
        </Row>
      </MenuItem>
    );
  };

  const list = (
    <MenuList>
      {isOverriding
        ? allRecipients.map((r) => (
            <OverrideMenuItem key={r.id} recipient={r} />
          ))
        : unusedRecipients.map((r) => <AddMenuItem key={r.id} recipient={r} />)}
      <MenuDivider />
      <MenuItem onClick={onAdd} icon={PlusIcon}>
        Create new recipient
      </MenuItem>
    </MenuList>
  );

  if (isIcon) {
    return (
      <Menu closeOnSelect={false}>
        <MenuIconButton
          aria-label="Add recipients"
          icon={PlusIcon}
          variant="secondary"
          size="sm"
        />
        {list}
      </Menu>
    );
  }

  if (!unusedRecipients.length) {
    return (
      <Button onClick={onAdd} variant="primary">
        Add new recipient
      </Button>
    );
  }

  return (
    <Menu closeOnSelect={false}>
      <MenuButton variant="primary">Add recipients</MenuButton>
      {list}
    </Menu>
  );
};
