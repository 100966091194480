import { useEffect, useMemo, useState } from "react";

import {
  Button,
  Column,
  DeleteIcon,
  Heading,
  Paragraph,
  Row,
  SectionHeading,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Helmet } from "react-helmet";
import { useNavigate } from "src/router";

import { CopilotTextArea } from "src/components/copilot-text-area";
import { PageHeader } from "src/components/layout";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { useUser } from "src/contexts/user-context";
import {
  useAddCopilotMessageMutation,
  useGetCopilotMessagesQuery,
} from "src/graphql";
import { hidePylon, showPylon } from "src/lib/pylon";
import { AnalyticsPlaceholderImage } from "src/pages/analytics/placeholders";
import { load, remove, save } from "src/utils/storage";

import { CopilotMessageArea } from "./copilot-message-area";
import { CopilotPlaceholder } from "./copilot-placeholder";
import { ErrorMessage, UserMessage } from "./types";

const MAX_WIDTH = 800;

const VAR_COPILOT_SESSION_ID = "copilot-session-id";

export const Copilot = () => {
  const { enableCopilot } = useFlags();
  const navigate = useNavigate();

  const { toast } = useToast();
  const { workspace } = useUser();

  useEffect(() => {
    hidePylon();

    return () => {
      showPylon();
    };
  }, []);

  const {
    isPermitted: hasPreviewPermission,
    isLoading: previewPermissionLoading,
  } = useResourcePermission({
    v1: { resource: "audience", grant: "preview" },
  });

  const compositeKey = workspace?.id
    ? `${VAR_COPILOT_SESSION_ID}-${workspace.id}`
    : undefined;

  const [sessionId, setSessionId] = useState<string | undefined>(() => {
    if (compositeKey) {
      return load(compositeKey)?.trim();
    }

    return undefined;
  });

  const [ephemeralMessages, setEphemeralMessages] = useState<
    Omit<UserMessage | ErrorMessage, "role">[]
  >([]);

  const addCopilotMessage = useAddCopilotMessageMutation();
  const {
    data,
    error,
    isLoading: historyLoading,
  } = useGetCopilotMessagesQuery(
    { chatSessionId: sessionId ?? "" },
    {
      enabled: hasPreviewPermission && Boolean(sessionId),
      keepPreviousData: true,
      // This keeps previous data. Set the chat messages to the specific session id
      // so that clearing the chat makes the messages inaccessible.
      select: (data) => ({ [sessionId ?? ""]: data.getCopilotChatMessage }),
      refetchInterval: 3000,
    },
  );

  const history = (sessionId && data?.[sessionId]) || [];

  const isPolling =
    (history.length > 0 && history?.[history.length - 1]?.role === "USER") ||
    (history.length === 0 && ephemeralMessages.length > 0);

  const messages = useMemo(() => {
    const messages = [...(history ?? [])];
    if (ephemeralMessages) {
      for (const message of ephemeralMessages) {
        if (
          !history?.some(
            (h) => h.content === message.content && h.role === "USER",
          )
        ) {
          messages.push({ role: "USER", chartDefinitions: [], ...message });
        }
      }
    }

    return messages;
  }, [history, ephemeralMessages]);

  const resetChat = () => {
    if (compositeKey) {
      remove(compositeKey);
    }

    setSessionId(undefined);
    setEphemeralMessages([]);
  };

  const submit = async (newValue: string) => {
    setEphemeralMessages((currentMessages) => [
      ...currentMessages,
      {
        timestamp: new Date().toISOString(),
        content: newValue,
      },
    ]);

    try {
      const response = await addCopilotMessage.mutateAsync({
        chatSessionId: sessionId ?? null,
        input: newValue,
      });

      if (!sessionId && response.addCopilotChatMessage?.chatSessionId) {
        setSessionId(response.addCopilotChatMessage.chatSessionId);

        if (compositeKey) {
          save(compositeKey, response.addCopilotChatMessage.chatSessionId);
        }
      }
    } catch (error) {
      toast({
        id: "copilot-submission-error",
        title: "Error submitting prompt",
        message: "Please try again",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (!enableCopilot) {
      navigate("/home");
    }
  }, [enableCopilot]);

  return (
    <>
      <Helmet>
        <title>Copilot</title>
      </Helmet>

      <PageHeader>
        <Row
          position="sticky"
          top={0}
          bg="white"
          width="100%"
          borderBottom="1px solid"
          borderColor="base.border"
          p={4}
          zIndex={1}
          justifyContent="space-between"
        >
          <Row gap={8}>
            <Heading size="xl">Copilot</Heading>
          </Row>
          {messages.length > 0 && (
            <Button icon={DeleteIcon} onClick={resetChat}>
              Clear
            </Button>
          )}
        </Row>
      </PageHeader>

      <Column height="100%">
        {hasPreviewPermission ? (
          <Column
            width="100%"
            height="100%"
            justify="space-between"
            maxWidth={`${MAX_WIDTH}px`}
            mx="auto"
          >
            {messages.length == 0 && (
              <Column align="center" justify="center" height="100%">
                <CopilotPlaceholder />
              </Column>
            )}
            <CopilotMessageArea
              data={sessionId ? messages ?? [] : []}
              error={error}
              isLoading={
                isPolling || historyLoading || addCopilotMessage.isLoading
              }
            />
            <Column position="sticky" bottom={0} pb={8} bg="white" zIndex={1}>
              <Column ml={12}>
                <CopilotTextArea
                  isDisabled={previewPermissionLoading}
                  placeholder={
                    messages.length > 0
                      ? "Ask a follow-up..."
                      : "What do you want to see?"
                  }
                  isLoading={addCopilotMessage.isLoading}
                  onSubmit={submit}
                />
              </Column>
            </Column>
          </Column>
        ) : (
          <Column justify="center" align="center" flex={1} minWidth={0}>
            <Column
              align="center"
              justify="center"
              flex={1}
              minHeight={0}
              textAlign="center"
              width="576px"
            >
              <AnalyticsPlaceholderImage />
              <SectionHeading mb={2}>
                You do not have permissions to use Analytics Copilot
              </SectionHeading>
              <Paragraph>
                Contact a workspace admin to gain access or to learn more.
              </Paragraph>
            </Column>
          </Column>
        )}
      </Column>
    </>
  );
};
