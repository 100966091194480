import {
  IntervalUnit,
  PredefinedAnalyticsMetricDefinition,
  UserDefinedAnalyticsMetricDefinition,
} from "@hightouch/lib/query/visual/types";
import { format } from "date-fns-tz";
import { isValid } from "date-fns";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import isEqualWith from "lodash/isEqualWith";
import { isPresent } from "ts-extras";

import {
  GraphSeries,
  GroupColumn,
} from "src/components/analytics/cross-audience-graph/types";
import { getSeriesName } from "src/components/analytics/cross-audience-graph/utils";
import { toSingleCondition } from "src/components/audiences/utils";
import {
  FilterableColumnFragment,
  FunnelMetricDataForCohort,
  FunnelMetricDataForCohortOrError,
} from "src/graphql";
import {
  AggregationOption,
  DEFAULT_ATTRIBUTION_WINDOW,
} from "src/pages/metrics/constants";
import {
  getAggregationConfiguration,
  mapAggregationConfigurationToConfigurationOption,
} from "src/pages/metrics/utils";
import {
  AnalyticsMetricDefinition,
  AndOrCondition,
  AttributionWindow,
  AudienceAggregationType,
  ColumnReference,
  EventCondition,
  PerUserAggregationType,
  PredefinedMetric,
  PropertyCondition,
  Relationship,
  initialEventCondition,
  initialFunnelCondition,
} from "src/types/visual";
import { getModelIdFromColumn } from "src/components/explore/visual/utils";

import { DEFAULT_FILTER, PREDEFINED_METRIC_OPTIONS } from "./constants";
import { MetricResultMaybeFromCache } from "./hooks/use-metric-series";
import { PLACEHOLDER_AUDIENCE, SECONDS_IN_ONE_DAY } from "./state/constants";
import {
  AudienceFilter,
  ConversionWindow,
  FunnelStep,
  FunnelStepGraphData,
  FunnelStepGraphDataPoint,
  FunnelTableData,
  GroupByColumn,
  Metric,
  MetricSelection,
  ParentModel,
  SavedChartFunnelStage,
  SelectedAudience,
  TimeOptions,
  isAdHocAudience,
} from "./types";

export const numberAndStringValidator = (valueA, valueB) => {
  if (
    (typeof valueA === "string" || typeof valueA === "number") &&
    (typeof valueB === "string" || typeof valueB === "number")
  ) {
    return valueA == valueB;
  }

  return undefined;
};

export function isValidDateRange(dateRange: Date[]): dateRange is [Date, Date] {
  return isValid(dateRange[0]) && isValid(dateRange[1]);
}

export function getNumberOfSeconds(
  window: ConversionWindow | undefined,
): number {
  if (!window) return 0;
  const { quantity, unit } = window;

  switch (unit) {
    case IntervalUnit.Day:
      return 60 * 60 * 24 * quantity;
    case IntervalUnit.Week:
      return 60 * 60 * 24 * 7 * quantity;
    case IntervalUnit.Month:
      return 60 * 60 * 24 * 30 * quantity;
    default:
      return 0;
  }
}

export const formatDatePickerLabel = (
  dateRange: Date[],
  timeValue: TimeOptions,
): string => {
  if (timeValue != TimeOptions.Custom || !isValidDateRange(dateRange)) {
    return "Custom";
  }

  return `${dateRange[0].toLocaleDateString()} - ${dateRange[1].toLocaleDateString()}`;
};

export const getInclusiveTimeWindow = (dateRange: [Date, Date]) => ({
  // Extract the year, month, and day from the date objects
  // while intentionally ignoring the browser's local timezone,
  // so we don't add any kind of offset to the dates.
  start: format(dateRange[0], "yyyy-MM-dd'T'00:00:00.000'Z'"),
  end: format(dateRange[1], "yyyy-MM-dd'T'23:59:59.000'Z'"),
});

export const isInitialMetric = (
  metric: Metric | undefined,
  {
    conditions,
    attributionWindow,
  }: {
    conditions: AndOrCondition<PropertyCondition>[];
    attributionWindow?: AttributionWindow;
  },
) => {
  if (!metric) {
    return false;
  }

  return (
    isEqual(
      conditions,
      toSingleCondition(metric?.config.filter?.subconditions),
    ) && isEqual(attributionWindow, metric.attribution_window)
  );
};

export const formatMetricsAndMetricDefinitions = (
  parentModelId: string | undefined,
  metricSelections: MetricSelection[],
  metrics: Metric[],
): { metricIds: string[]; metricDefinitions: AnalyticsMetricDefinition[] } => {
  const metricIds: string[] = [];
  const metricDefinitions: AnalyticsMetricDefinition[] = [];

  if (!parentModelId) {
    // Bail if there is no parent model ID since it's needed for to make queries
    return {
      metricIds,
      metricDefinitions,
    };
  }

  metricSelections.forEach((metricSelection) => {
    if (!metricSelection.id) {
      return;
    }

    const initialMetric = getMetricById(metrics, metricSelection.id);

    if (isInitialMetric(initialMetric, metricSelection)) {
      // The selected metric is already persisted, so we can reference it by the ID
      metricIds.push(metricSelection.id);
    } else if (
      Object.values(PredefinedMetric).includes(
        metricSelection.id as PredefinedMetric,
      )
    ) {
      // The selected metric is "pre-defined" -- a special metric that is not created by the user,
      // but is understood by the backend. It has its own definition.
      const metric: PredefinedAnalyticsMetricDefinition = {
        parentModelId: parentModelId.toString(),
        predefinedMetric: metricSelection.id,
      };
      metricDefinitions.push(metric);
    } else {
      // The selected metric is ad-hoc, so we need to build out a valid definition for it
      const eventModelId =
        initialMetric?.config.eventModelId.toString() ??
        metricSelection?.eventModelId?.toString() ??
        "";
      const relationshipId =
        initialMetric?.config.relationshipId.toString() ??
        metricSelection.id.toString();
      const column =
        initialMetric && "column" in initialMetric.config
          ? initialMetric?.config.column
          : metricSelection.column?.column_reference ?? null;

      const aggregationConfiguration = getAggregationConfiguration(
        metricSelection?.aggregationMethod ?? AggregationOption.Count,
      );

      const metric: UserDefinedAnalyticsMetricDefinition = {
        parentModelId: parentModelId?.toString() ?? "",
        ...(aggregationConfiguration ?? {
          aggregation: PerUserAggregationType.Count,
          audienceAggregation: AudienceAggregationType.Sum,
        }),
        attributionWindow:
          metricSelection.attributionWindow ?? DEFAULT_ATTRIBUTION_WINDOW,
        config: {
          eventModelId,
          relationshipId,
          // If we don't have a column from the initialMetric, grab it from the metricSelection
          column: column ?? metricSelection.column?.column_reference,
          filter: {
            subconditions: metricSelection.conditions,
          },
        },
      };

      metricDefinitions.push(metric);
    }
  });

  return { metricIds, metricDefinitions };
};

export const mapMetricToMetricSelection = (
  goalDefinition: Metric,
  metrics: Metric[],
  parentColumns: FilterableColumnFragment[],
  events: Relationship[],
): MetricSelection | null => {
  if (!goalDefinition.config.relationshipId) return null;

  // need to get the metric or event name and id
  const event = events?.find(({ id }) => id.toString() === goalDefinition.id);
  const metric = metrics.find(({ id }) => id.toString() === goalDefinition.id);
  const predefinedMetric = PREDEFINED_METRIC_OPTIONS.find(
    ({ id }) => id === goalDefinition.id,
  );

  const aggregationMethod =
    mapAggregationConfigurationToConfigurationOption(goalDefinition) ??
    AggregationOption.Count;

  let name = "";
  let description: string | null = null;

  if (metric) {
    name = metric.name;
    description = metric.description;
  } else if (event) {
    name = event.to_model.name ?? event.name;
    description = event.to_model.description;
  } else if (predefinedMetric) {
    name = predefinedMetric.name;
  }

  return {
    // These need to be NUMBERS but the type says string >.<
    id: Number(goalDefinition.config.relationshipId) as unknown as string,
    eventModelId: goalDefinition.config.eventModelId
      ? (Number(goalDefinition.config.eventModelId) as unknown as string)
      : null,
    name,
    description,
    aggregationMethod,
    conditions: goalDefinition.config.filter.subconditions ?? [],
    attributionWindow: goalDefinition.attribution_window,
    column: goalDefinition.config.column
      ? parentColumns
          .concat(event?.to_model.filterable_audience_columns ?? [])
          .find(({ column_reference }) => {
            return isEqualWith(
              column_reference,
              goalDefinition.config.column,
              numberAndStringValidator,
            );
          })
      : undefined,
  };
};

export const separateMetricsAndMetricDefinitions = (
  parentModelId: string | undefined,
  metricSelections: MetricSelection[],
  metrics: Metric[],
): { metricIds: string[]; metricDefinitions: MetricSelection[] } => {
  const metricIds: string[] = [];
  const metricDefinitions: MetricSelection[] = [];

  if (!parentModelId) {
    // Bail if there is no parent model ID since it's needed for to make queries
    return {
      metricIds,
      metricDefinitions,
    };
  }

  metricSelections.forEach((metricSelection) => {
    if (!metricSelection.id) {
      return;
    }

    const initialMetric = getMetricById(metrics, metricSelection.id);

    if (isInitialMetric(initialMetric, metricSelection)) {
      // The selected metric is already persisted, so we can reference it by the ID
      metricIds.push(metricSelection.id);
    } else {
      metricDefinitions.push(metricSelection);
    }
  });

  return { metricIds, metricDefinitions };
};

export const separateCohortsAndCohortDefinitions = (
  parentModelId: number | string | undefined | null,
  audiences: SelectedAudience[],
) => {
  const cohortIds: string[] = [];
  const cohortDefinitions: {
    parentModelId: string;
    filter: AudienceFilter;
    name: string;
  }[] = [];

  for (const audience of audiences) {
    if (audience.id == parentModelId) {
      const filter = isAdHocAudience(audience)
        ? audience.filter
        : DEFAULT_FILTER;

      cohortDefinitions.push({
        parentModelId: audience.id.toString(),
        filter,
        name: audience.name,
      });
    } else if (audience.id !== PLACEHOLDER_AUDIENCE.id) {
      cohortIds.push(audience.id.toString());
    }
  }

  return { cohortIds, cohortDefinitions };
};

export const getMetricById = (
  metrics: Metric[],
  metricId: string,
): Metric | undefined => {
  return metrics.find(({ id }) => id === metricId);
};

export const getEventById = (
  events: Relationship[],
  {
    eventModelId,
    relationshipId,
  }: { eventModelId?: string | number; relationshipId?: string | number },
): Relationship | undefined => {
  return events.find(
    ({ id, to_model }) =>
      id.toString() === relationshipId?.toString() ||
      to_model.id.toString() === eventModelId?.toString(),
  );
};

export const getEventIdFromMetricSelection = (
  metric: MetricSelection,
  metrics: Metric[],
) => {
  if (metric.eventModelId) {
    return metric.eventModelId;
  }

  if (metric.id) {
    return getMetricById(metrics, metric.id)?.config.eventModelId;
  }

  return null;
};

export const getMetricSelectionFromMetric = ({
  metricId,
  events,
  metrics,
}: {
  metricId: string;
  events: Relationship[];
  metrics: Metric[];
}): MetricSelection | undefined => {
  const event = events?.find(({ id }) => id === metricId);
  const metric = metrics.find(({ id }) => id === metricId);
  const predefinedMetric = PREDEFINED_METRIC_OPTIONS.find(
    ({ id }) => id === metricId,
  );

  let data: MetricSelection | undefined;

  if (metric) {
    data = {
      id: metric.id,
      eventModelId: null,
      name: metric.name,
      description: metric.description,
      aggregationMethod:
        mapAggregationConfigurationToConfigurationOption(metric) ??
        AggregationOption.Count,
      attributionWindow: metric?.attribution_window,
      conditions:
        (metric?.config.filter?.subconditions as PropertyCondition[]) ?? [],
    };
  } else if (event) {
    data = {
      id: event.id,
      eventModelId: event.to_model.id,
      name: event.to_model.name ?? event.name,
      description: event.to_model.description,
      aggregationMethod: AggregationOption.Count,
      attributionWindow: undefined,
      conditions: [],
    };
  } else if (predefinedMetric) {
    data = {
      id: predefinedMetric.id,
      eventModelId: null,
      name: predefinedMetric.name,
      description: null,
      // `aggregationMethod` is ignored for predefined metrics because
      // it has a specific aggregation that can't be modified by the user.
      // This is just a placeholder to satisfy typechecking.
      // TODO(samuel): think of a better way to do this. Maybe by creating an AggregationOption.Unknown.
      aggregationMethod: AggregationOption.Count,
      attributionWindow: undefined,
      conditions: [],
    };
  }

  return data;
};

export const getNumberOfUniqueValues = <T>(
  values: Array<T>,
  path?: string | null,
  omit?: (string | boolean | number | undefined | null)[],
): number => {
  let result = 0;
  const map = new Map<T, number>();

  values.forEach((dataPoint) => {
    const value = path ? get(dataPoint, path) : dataPoint;

    if (omit?.includes(value)) {
      return;
    }

    const count = map.get(value);

    map.set(path ? `${path}:${value}` : value, count ?? 1);
  });

  map.forEach((value) => (result += value));

  return result;
};

export const getUnsupportedMetricNamesForGroupByColumn = ({
  events,
  groupByColumn,
  metrics,
  metricSelection,
  parent,
}: {
  parent: ParentModel | null;
  groupByColumn: GroupByColumn | undefined;
  metricSelection: MetricSelection[];
  metrics: Metric[];
  events: Relationship[];
}): string[] => {
  // step 1: get events that are selected that aren't applicable
  const unsupportedMetricNames: string[] = [];

  if (groupByColumn) {
    const isRelatedToParent = isGroupByColumnRelatedToParent(
      parent,
      groupByColumn,
    );

    const relatedEvent =
      !isRelatedToParent &&
      events.find((event) =>
        isGroupByColumnRelatedToEvent(event, groupByColumn),
      );
    const isRelatedToEvent = Boolean(relatedEvent);

    const parentId = parent?.id.toString();

    // The group by column's id. If it's a related column
    // then the id will be the related model's id
    const groupByModelId = isRelatedToParent
      ? parentId
      : isRelatedToEvent && relatedEvent
        ? relatedEvent?.to_model?.id
        : getModelIdFromColumn(groupByColumn);

    // find selected metrics unsupported by this groupBy column model
    metricSelection.forEach((metric) => {
      if (groupByModelId == parentId) {
        // Column is part of the parent model or related to parent model
        // (i.e. merge column), then ignore
        return;
      }

      // if groupBy column is not a part of the parent model, check if it's a part
      // of the saved metric's event model
      if (metric.id && metric.eventModelId === null) {
        // metric
        const metricDefinition = getMetricById(metrics, metric.id);
        const eventModelId = metricDefinition?.config?.eventModelId?.toString();

        if (groupByModelId != eventModelId) {
          metricDefinition?.name &&
            unsupportedMetricNames.push(`"${metricDefinition?.name}"`);
        }
        // finally check the 'live' metric, i.e. event
      } else if (
        metric.id &&
        metric.eventModelId &&
        groupByModelId != metric.eventModelId?.toString()
      ) {
        const event = getEventById(events, {
          eventModelId: metric.eventModelId,
          relationshipId: metric.id,
        });

        event?.to_model.name &&
          unsupportedMetricNames.push(`"${event.to_model.name}"`);
      }
    });
  }

  return unsupportedMetricNames;
};

export const getMaxBreakdownValue = (data: GraphSeries[]) => {
  let maxValue = 0;

  data.forEach(({ data }) => {
    const value = data?.[0]?.metricValue ?? 0;
    maxValue = Math.max(value, maxValue);
  });

  return maxValue;
};

export const getSavedChartData = ({
  cohorts,
  customDateRange,
  funnelSteps,
  metrics,
  metricSelection,
  parentModelId,
  timeValue,
}: {
  cohorts: SelectedAudience[];
  customDateRange: Date[];
  funnelSteps: FunnelStep[];
  metrics: Metric[];
  metricSelection: MetricSelection[];
  parentModelId: number | undefined | null;
  timeValue: TimeOptions;
}) => {
  const { metricIds, metricDefinitions } = separateMetricsAndMetricDefinitions(
    parentModelId?.toString() ?? "",
    metricSelection,
    metrics,
  );

  const formattedMetricIds = metricIds.map((id) => ({ goal_id: id }));
  const formattedMetricDefinitions = metricDefinitions.map((value) => ({
    goal_definition: value,
  }));
  const metricData: (
    | { goal_id: string }
    | { goal_definition: MetricSelection }
  )[] = [...formattedMetricIds, ...formattedMetricDefinitions];
  const { cohortIds, cohortDefinitions } = separateCohortsAndCohortDefinitions(
    parentModelId,
    cohorts,
  );

  const formattedCohortIds = cohortIds.map((id) => ({ cohort_id: id }));
  const formattedCohortDefinitions = cohortDefinitions.map((value) => ({
    cohort_definition: value,
  }));
  const cohortData: { cohort_id?: string; cohort_definition?: object }[] = [
    ...formattedCohortIds,
    ...formattedCohortDefinitions,
  ];

  const funnelStageData: SavedChartFunnelStage[] = funnelSteps
    .map(({ eventModelId, relationshipId, subconditions }) => {
      if (!eventModelId || !relationshipId) return null;

      const formattedRow = {
        event_model_id: eventModelId,
        relationship_id: relationshipId,
      };
      if (subconditions.length > 0) {
        return { ...formattedRow, funnel_stage_definition: { subconditions } };
      }

      return formattedRow;
    })
    .filter(isPresent);

  // lookbackWindow is derived from `timeValue` and `customDateRange`
  let lookbackWindow: { start: string; end: string } | { lookback: string };

  if (timeValue === TimeOptions.Custom && isValidDateRange(customDateRange)) {
    lookbackWindow = getInclusiveTimeWindow(customDateRange);
  } else {
    lookbackWindow = { lookback: timeValue };
  }

  return {
    metrics: metricData,
    cohorts: cohortData,
    funnelSteps: funnelStageData,
    lookbackWindow,
  };
};

export const transformFunnelDataForGraph = ({
  audiences,
  data,
  events,
}: {
  audiences: SelectedAudience[];
  data: FunnelMetricDataForCohort[];
  events: Relationship[];
}): FunnelStepGraphData[] => {
  // Add all steps into funnel data
  const funnelData: FunnelStepGraphData[] =
    data?.[0]?.groups?.[0]?.stages.map(({ stage }, index) => {
      const event = getEventById(events ?? [], stage);

      return {
        stageName: event?.to_model.name ?? `Event ${stage.relationshipId}`,
        eventModelId: event?.to_model.id ?? "",
        relationshipId: event?.id ?? "",
        subconditions: stage.filter?.conditions ?? null,
        groupByValue: undefined,
        index,
        data: {},
      };
    }) ?? [];

  // Format data for funnel graph
  data.forEach((cohortPairing) => {
    const cohort = cohortPairing?.cohort;
    const audienceId = Number(
      cohort?.cohortDefinition?.parentModelId ?? cohort?.cohortId,
    );

    const foundAudience = findAudienceFromCohort(
      audiences,
      cohort?.cohortDefinition?.filter,
      audienceId,
    );

    const audienceName = foundAudience?.name ?? "--";

    (cohortPairing?.groups ?? []).forEach(({ groupBy, splitId, stages }) => {
      // XXX: Need to replace all brackets bc recharts dataKey's won't work with
      // them since the key is used as dot notation to access the data. This is
      // a quick work around (longer term solution would need to update the dataKey
      // to be a function that grabs the data instead and update our hover state
      // tooltip to account for that)
      const seriesName = getSeriesName({
        audienceName,
        splitName: splitId,
        groupByColumns: groupBy,
      })
        .replaceAll("[", "(")
        .replaceAll("]", ")");

      const groupByValue = groupBy?.[0]?.value ?? "--";

      // stage is the selected step
      stages.forEach(({ count }, index) => {
        let rowData: FunnelStepGraphDataPoint = {
          count,
          conversion: 0,
          conversionBarSize: 0,
          dropOffBarSize: 0,
          groupByValue,
        };

        if (index === 0) {
          // First step
          rowData.conversion = count !== 0 ? 1 : 0;
          rowData.conversionBarSize = count !== 0 ? 1 : 0;
        } else {
          const initialItem = stages[0]!;
          const previousItem = stages[index - 1]!;
          const previousRow = funnelData[index - 1]!.data[seriesName]!;

          const initialCount = initialItem.count;
          const previousCount = previousItem.count;

          const conversionBarSize =
            initialCount === 0 ? 0 : count / initialCount;

          rowData = {
            count,
            groupByValue,
            conversion: previousCount === 0 ? 0 : count / previousCount, // conversion from previous to current step
            conversionBarSize, // current count / initial count
            dropOffBarSize: previousRow.conversionBarSize - conversionBarSize,
          };
        }

        funnelData[index]!.data[seriesName] = rowData;
      });
    });
  });

  return funnelData;
};

export function filterMetricDataForCohort(
  metricData: FunnelMetricDataForCohortOrError[],
): FunnelMetricDataForCohort[] {
  const result: FunnelMetricDataForCohort[] = [];

  metricData.forEach((data) => {
    if ("groups" in data) {
      result.push(data);
    }
  });

  return result;
}

export const getTableDataFromFunnelData = (
  graphData: FunnelStepGraphData[],
): FunnelTableData[] => {
  if (graphData.length === 0) return [];

  // 1. Extract series names
  const seriesNames = Object.keys(graphData[0]!.data);

  // 2. Organize the data
  return seriesNames.map((name) => {
    const row: FunnelTableData = {
      seriesName: name,
      conversion: graphData[graphData.length - 1]!.data[name]!.conversion,
      steps: graphData.map(({ stageName, data }) => ({
        stageName,
        numberOfUsers: data[name]!.count,
        conversion: data[name]!.conversion,
      })),
    };

    return row;
  });
};

export const removeDisabledSplitGroupsFromMetricResult = (
  data: MetricResultMaybeFromCache[],
  audiences: {
    id: string | number;
    name: string;
    splits: { enabled: boolean; name: string }[];
  }[],
) => {
  const disabledSplitMap: Record<string, Set<string>> = audiences.reduce(
    (all, { id, splits = [] }) => {
      const disabledSplitNames = new Set(
        splits.filter(({ enabled }) => !enabled).map(({ name }) => name),
      );
      all[id.toString()] = disabledSplitNames;
      return all;
    },
    {},
  );

  return data.map((series) => {
    if ("data" in series.result) {
      const disabledSeriesNames =
        series.ids.cohortId && disabledSplitMap[series.ids.cohortId.toString()];

      if (disabledSeriesNames) {
        return {
          ...series,
          result: {
            ...series.result,
            data: series.result.data.filter(
              ({ splitId }) =>
                splitId === null || !disabledSeriesNames.has(splitId),
            ),
          },
        };
      }
    }

    return series;
  });
};

export const removeDisabledSplitGroupsFromFunnels = (
  data: FunnelMetricDataForCohort[],
  audiences: {
    id: string | number;
    name: string;
    splits: { enabled: boolean; name: string }[];
  }[],
): FunnelMetricDataForCohort[] => {
  const disabledSplitMap: Record<string, Set<string>> = audiences.reduce(
    (all, { id, splits = [] }) => {
      const disabledSplitNames = new Set(
        splits.filter(({ enabled }) => !enabled).map(({ name }) => name),
      );
      all[id.toString()] = disabledSplitNames;
      return all;
    },
    {},
  );

  return data.map((series) => {
    const disabledSeriesNames =
      series.cohort.cohortId &&
      disabledSplitMap[series.cohort.cohortId.toString()];

    if (disabledSeriesNames) {
      return {
        ...series,
        groups: series.groups.filter(
          ({ splitId }) =>
            splitId === null || !disabledSeriesNames.has(splitId),
        ),
      };
    }

    return series;
  });
};

// TODO(samuel): update once event conditions support more than two events
export const createEventConditionFromFunnelSteps = ({
  stage,
  secondStage,
  didPerform = true,
}: {
  stage: Omit<FunnelStep, "id">;
  secondStage?: Omit<FunnelStep, "id">;
  didPerform?: boolean;
}): EventCondition => {
  const eventCondition: EventCondition = {
    ...initialEventCondition,

    eventModelId: stage.eventModelId ?? null,
    relationshipId: stage.relationshipId ?? null,

    subconditions: stage.subconditions,
  };

  if (secondStage) {
    eventCondition.funnelCondition = {
      ...initialFunnelCondition,

      eventModelId: secondStage.eventModelId ?? null,
      relationshipId: secondStage.relationshipId ?? null,

      didPerform,

      subconditions: secondStage.subconditions,
    };
  }

  return eventCondition;
};

export const getTimeValueAndSelectedDates = (
  lookbackWindow:
    | {
        start: string;
        end: string;
      }
    | {
        lookback: TimeOptions | number;
      }
    | undefined,
): { timeValue: TimeOptions; selectedDates: string[] } => {
  let timeValue: TimeOptions = TimeOptions.Custom;
  const selectedDates: string[] = [];

  if (lookbackWindow && "lookback" in lookbackWindow) {
    if (typeof lookbackWindow.lookback === "number") {
      switch (lookbackWindow.lookback) {
        case SECONDS_IN_ONE_DAY * 30:
          timeValue = TimeOptions.ThirtyDays;
          break;
        case SECONDS_IN_ONE_DAY * 60:
          timeValue = TimeOptions.SixtyDays;
          break;
        case SECONDS_IN_ONE_DAY * 90:
          timeValue = TimeOptions.NinetyDays;
          break;
        case SECONDS_IN_ONE_DAY * 7:
        default:
          timeValue = TimeOptions.SevenDays;
          break;
      }
    } else {
      timeValue = lookbackWindow.lookback;
    }
  }

  if (
    timeValue === TimeOptions.Custom &&
    lookbackWindow &&
    "start" in lookbackWindow
  ) {
    selectedDates.push(lookbackWindow.start, lookbackWindow.end);
  }

  return { timeValue, selectedDates: selectedDates.filter(isPresent) };
};

export const isGroupByColumnRelatedToParent = (
  parent: ParentModel | null,
  groupByColumn: GroupByColumn | undefined,
) => {
  return Boolean(
    parent?.filterable_audience_columns?.find(({ column_reference }) =>
      // in case modelId is not a string
      isEqualWith(column_reference, groupByColumn, numberAndStringValidator),
    ),
  );
};

export const isGroupByColumnRelatedToEvent = (
  event: Relationship | null,
  groupByColumn: GroupByColumn | undefined,
) => {
  return Boolean(
    event?.to_model?.filterable_audience_columns?.find(({ column_reference }) =>
      // in case modelId is not a string
      isEqualWith(column_reference, groupByColumn, numberAndStringValidator),
    ),
  );
};

export const getColumnReferenceFromGroupByColumn = (
  column: GroupByColumn | GroupColumn,
): ColumnReference => {
  return "column" in column ? column.column : column;
};

export const findAudienceFromCohort = (
  audiences: SelectedAudience[],
  cohortFilter: AudienceFilter | undefined,
  cohortId: number,
) => {
  // Find the audience by the filter if it's an ad hoc audience since these
  // audiences can have the same id as the parent model
  return audiences.find((a) => {
    if (cohortFilter?.conditions?.length) {
      // We only want to compare filter to filter if we know the cohort
      // audience is an ad hoc audience so we avoid comparing empty conditions
      return isAdHocAudience(a) ? isEqual(a.filter, cohortFilter) : false;
    }

    return a.id == cohortId && !isAdHocAudience(a);
  });
};
