// Types to pass to the recommender.
// We use camel case here since we are going to send this
import { type Static, Type } from "@sinclair/typebox";
import { IntervalUnit, type RelatedColumn } from "../../query/visual/types";
import type { RawSchemaName } from "../../sync/planner/in-warehouse/adapter-types";
import { InputFeatureSchema } from "./input-feature-schema";

// over the network to the recommender.
export interface DecisionEngineChannel {
  name: string;
  messages: DecisionEngineMessage[];
  tags: string[];
}

export enum DecisionEngineStatus {
  PENDING = "pending",
  TRAINING = "training",
  READY = "ready",
}

export type DecisionEngineChannelType = "email" | "push" | "sms" | "raw";

export type DecisionEngineChannelConfigType =
  | "dataExtensions"
  | "trigger"
  | "canvas";

export interface DecisionEngineChannelConfig {
  externalId: string;
  recipientType?: "email" | "userId";
  type?: DecisionEngineChannelConfigType;
}

export interface DecisionEngineMessage {
  id: string;
  campaign_id: string;
  variables: Variable[];
  items: DecisionEngineItem[];
  max_sends_per_user?: number;
  max_sends_per_user_window_days?: number;
  min_other_messages_in_between?: number;
  first_eligible_send_date?: string;
  last_eligible_send_date?: string;
  user_filter?: Condition;
  tags?: string[];
}

export interface Variable {
  name: string;
  values: string[];
}

enum Day {
  Sunday = "sunday",
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
}

enum Time {
  Morning = "morning",
  Afternoon = "afternoon",
  Evening = "evening",
  Night = "night",
}

enum Frequency {
  Weekly1x = "weekly1x",
  Weekly2x = "weekly2x",
  Weekly3x = "weekly3x",
  Weekly4x = "weekly4x",
  Weekly5x = "weekly5x",
  Weekly6x = "weekly6x",
  Weekly7x = "weekly7x",
  Every2Week = "every2week",
  Monthly = "monthly",
}

export interface Timing {
  days: Day[];
  times: Time[];
  frequency: Frequency[];
}

export interface DecisionEngineItem {
  collection_id: string;
  num_items_to_recommend: number;
}

// Internal types used by the decision engine orchestrator to prepare inputs
// and process outputs.
export interface DecisionEngineConfig {
  // Set by the UI
  timing: Timing;
  // Default to "America/Los_Angeles"
  scheduling_timezone?: string;
  slack_channel?: string;
  // The rest of this is set via the admin resolvers
  name: string;
  feature_model_id: string;
  user_feature_schema: FeatureSchema;
  // Traits or merge columns that need to be in the `hightouch_users` blob
  user_additional_columns: {
    column: RelatedColumn;
    alias: string;
  }[];
  attribution: {
    window: {
      unit: IntervalUnit;
      value: number;
    };
  };
  // Optional override for the output schema (if we don't want hightouch_planner)
  output_schema?: RawSchemaName;
  // Optional days ahead to start completing the message
  message_completion_interval_days?: number;
  action_model_configuration?: ModelConfiguration;
  // Always run decision engine regardless of COMMIT or ROLLBACK
  ignore_s3_state?: boolean;
}

export interface ModelConfiguration {
  enable_cross_validation: boolean;
  heuristic_function_id?: string;
  predictive_modeling_enabled?: boolean;
  modeling_type?: "classification" | "regression";
  epsilon?: number;
  n_jobs?: number;
  pre_dispatch?: number | string;
  downsample_max_rows_with_zero_reward?: number;
  grid_search_params?: object;
  sklearn_verbosity?: number;
  ignore_recent_days_count?: number;
}

export interface DecisionEngineFlowConfig {
  holdout: number;
  // Optional start date for the flow. Allows for configuring one and then having it launch in the future
  flow_start_date?: string;
}

export type DecisionEngineOutcomeReward = {
  type: "positive" | "negative";
  column?: string;
  priority: number;
};

export interface DecisionEngineResponse {
  status: string;
}

export type FeatureSchema = {
  name: string;
  type:
    | "integer"
    | "float"
    | "categorical"
    | "boolean"
    | "passthrough"
    | "sparse_count";
  computed?: boolean;
}[];

export type ItemFilter = Condition;

// These are similar to the audience types, but simplified.
// We don't need all the same features as audiences here, so we
// define a subset of the types.
type AndCondition = {
  type: "and";
  conditions: Condition[];
};

type OrCondition = {
  type: "or";
  conditions: Condition[];
};

type ColumnReference = {
  table: "users" | "items";
  name: string;
};

export type PropertyCondition = {
  type: "property";
  property: ColumnReference;
  value: string | ColumnReference;
  operator: ">" | "<" | "=" | "!=" | ">=" | "<=";
};

export type Condition = AndCondition | OrCondition | PropertyCondition;

// These are types from the database that we use to define the shape of the
// decision_engine_messages.config JSONB column based on the type of message.
export interface DecisionEngineMessageConfig {
  baseMessageId: string;
}

export interface DecisionEngineFlowMessageConfig {
  campaignId: string;
  // Used to track non campaign resources like Klaviyo flows
  resourceId?: string;
  attributionCampaignIds?: string[];
  maxSendsPerUser?: number;
  maxSendsPerUserWindowDays?: number;
  minOtherMessagesInBetween?: number;
  firstEligibleSendDate?: string;
  lastEligibleSendDate?: string;
  userFilter?: Condition;
}

export interface DecisionEngineCollectionConfig {
  schema: FeatureSchema;
  reward_model_configuration?: ModelConfiguration;
}

export interface DecisionEngineOutcomeConfig {
  metadata_column?: string;
  // If specified, we will filter the event table by this column using the campaign IDs from the
  // flow messages.
  campaign_id_column?: string;
  // If specified, we will offset the outcome timestamp by this number of hours, for example, our interactions
  // are stored in UTC, and Petsmart's email engagement data is in PT, so we need to offset by +8 hours
  // to match the timestamps. This is only needed for when their data is incorrectly stored with the wrong time zone.
  timestamp_offset_hours?: number;
}

export enum DecisionEngineRunStatus {
  Pending = "pending",
  Running = "running",
  Success = "success",
  Error = "error",
  Cancelled = "cancelled",
}

export enum DecisionEngineFlowRunStatus {
  Pending = "pending",
  Running = "running",
  Success = "success",
  Error = "error",
  Cancelled = "cancelled",
  Skipped = "skipped",
}

// Typebox types for validation
export const FeatureSchemaTypebox = Type.Array(InputFeatureSchema, {
  minItems: 1,
  uniqueItems: true,
});
export type FeatureSchemaTypebox = Static<typeof FeatureSchemaTypebox>;
export type ConfigInputTypebox = Static<typeof ConfigInputTypebox>;
enum ModelingType {
  Classification = "classification",
  Regression = "regression",
}

export const ModelConfigurationTypebox = Type.Object(
  {
    enable_cross_validation: Type.Optional(Type.Boolean()),
    heuristic_function_id: Type.Optional(Type.String()),
    predictive_modeling_enabled: Type.Optional(Type.Boolean()),
    modeling_type: Type.Optional(Type.Enum(ModelingType)),
    epsilon: Type.Optional(Type.Number()),
    n_jobs: Type.Optional(Type.Integer()),
    pre_dispatch: Type.Optional(Type.Union([Type.Number(), Type.String()])),
    downsample_max_rows_with_zero_reward: Type.Optional(Type.Integer()),
    grid_search_params: Type.Optional(
      Type.Object(
        {
          logistic_regression: Type.Optional(
            Type.Object(
              {
                enabled: Type.Optional(Type.Boolean()),
                c: Type.Optional(Type.Array(Type.Number())),
                max_iter: Type.Optional(Type.Array(Type.Integer())),
              },
              { additionalProperties: false },
            ),
          ),
          random_forest: Type.Optional(
            Type.Object(
              {
                enabled: Type.Optional(Type.Boolean()),
                n_estimators: Type.Optional(Type.Array(Type.Integer())),
                max_depth: Type.Optional(Type.Array(Type.Integer())),
                n_jobs: Type.Optional(Type.Integer()),
              },
              { additionalProperties: false },
            ),
          ),
          light_gbm: Type.Optional(
            Type.Object(
              {
                enabled: Type.Optional(Type.Boolean()),
                n_estimators: Type.Optional(Type.Array(Type.Integer())),
                max_depth: Type.Optional(Type.Array(Type.Integer())),
                learning_rate: Type.Optional(Type.Array(Type.Number())),
                subsample: Type.Optional(Type.Array(Type.Number())),
                colsample_bytree: Type.Optional(Type.Array(Type.Number())),
                n_jobs: Type.Optional(Type.Integer()),
              },
              { additionalProperties: false },
            ),
          ),
          xgboost: Type.Optional(
            Type.Object(
              {
                enabled: Type.Optional(Type.Boolean()),
                n_estimators: Type.Optional(Type.Array(Type.Integer())),
                max_depth: Type.Optional(Type.Array(Type.Integer())),
                learning_rate: Type.Optional(Type.Array(Type.Number())),
                subsample: Type.Optional(Type.Array(Type.Number())),
                colsample_bytree: Type.Optional(Type.Array(Type.Number())),
                n_jobs: Type.Optional(Type.Integer()),
              },
              { additionalProperties: false },
            ),
          ),
          ridge: Type.Optional(
            Type.Object(
              {
                enabled: Type.Optional(Type.Boolean()),
                solver: Type.Optional(Type.Array(Type.String())),
              },
              { additionalProperties: false },
            ),
          ),
        },
        { additionalProperties: false },
      ),
    ),
    sklearn_verbosity: Type.Optional(Type.Integer()),
    ignore_recent_days_count: Type.Optional(Type.Integer()),
  },
  { additionalProperties: false },
);
export type ModelConfigurationTypebox = Static<
  typeof ModelConfigurationTypebox
>;

export const CollectionInputTypebox = Type.Object({
  schema: FeatureSchemaTypebox,
  reward_model_configuration: Type.Optional(ModelConfigurationTypebox),
});

export type CollectionSchemaTypebox = Static<typeof CollectionInputTypebox>;

export const ConfigInputTypebox = Type.Object({
  name: Type.String(),
  feature_model_id: Type.Integer(),
  user_feature_schema: FeatureSchemaTypebox,
  action_model_configuration: Type.Optional(ModelConfigurationTypebox),
  attribution: Type.Object({
    window: Type.Object({
      unit: Type.Enum(IntervalUnit),
      value: Type.Number(),
    }),
  }),
  output_schema: Type.Optional(Type.String()),
  message_completion_interval_days: Type.Optional(Type.Integer()),
});

export const DECISION_ENGINE_SUPPORTED__DESTINATIONS: Record<
  DecisionEngineChannelType,
  string[] | null
> = {
  email: ["iterable", "sfmc", "braze", "klaviyo"],
  sms: ["iterable", "sfmc", "braze", "klaviyo"],
  push: ["iterable", "sfmc", "braze", "klaviyo"],
  raw: null, // Support all destinations
};

export interface DecisionEngineInputs {
  start_at?: number;
  interactions_input_file_path: string;
  interactions_output_file_path: string;
  // a parquet file, except on initial backfills.
  completed_interactions_input_file_path: string;
  // a parquet file.
  completed_interactions_output_file_path: string;
  // an ndjson file with just the new interactions of this run.
  completed_interactions_incremental_ndjson_output_file_path: string;
  policy_data_output_file_path: string;
  rewards_file_path: string;
  decision_engine_id: string;
  timing: Timing;
  action_model_configuration?: ModelConfiguration;
  experiments: {
    name: string;
    audience_percent: number;
  }[];
  collections: {
    name: string;
    collection_id: string;
    schema: FeatureSchema;
    file_path: string;
    item_filter: ItemFilter;
    reward_model_configuration?: ModelConfiguration;
    previous_item_engagement_file_path: string;
  }[];
  users: {
    scheduling_timezone: string;
    schema: FeatureSchema;
    file_path: string;
    frequency_state_input_path: string;
    frequency_state_output_path: string;
  };
  channels: DecisionEngineChannel[];
  flow_start_date?: string;
  message_completion_interval_days?: number;
  visualizations_output_directory?: string;
}
