import { FC } from "react";

import { Box, ErrorIcon, Row, Text, Tooltip } from "@hightouchio/ui";
import { DecisionEngineChannelConfig } from "@hightouch/lib/customer-data/decision-engine/types";
import { capitalize } from "lodash";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { LinkWithTooltip } from "src/components/link-with-tooltip";
import { Skeleton } from "src/components/loading";
import {
  useDecisionEngineDestinationResourcesQuery,
  useFormkitSyncHandlerQuery,
} from "src/graphql";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";
import { OutletContext } from "src/pages/decision-engines";
import { useOutletContext } from "src/router";

type Props = {
  channel: {
    config: DecisionEngineChannelConfig;
    destination: {
      id: string;
      type: string;
      config: Record<string, unknown>;
      definition: {
        icon: string;
        name: string;
      };
    };
  };
  resourceId: string;
};

export const DestinationResourceLink: FC<Readonly<Props>> = ({
  channel,
  resourceId,
}) => {
  const { resource, isLoading, error, url } = useDestinationResource({
    channel,
    resourceId,
  });

  if (isLoading) {
    return <Skeleton width="100px" />;
  }

  if (error) {
    return <Text color="danger.base">Failed to load</Text>;
  }

  const definition = getChannelDefinition(channel.destination.type);
  const resourceType = definition.getResourceName(channel.config);

  if (!resource) {
    return (
      <Text color="text.secondary" whiteSpace="nowrap">
        {capitalize(resourceType)} not added
      </Text>
    );
  }

  return (
    <Row
      gap={2}
      align="center"
      overflow="hidden"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <IntegrationIcon
        name={channel.destination.definition.name}
        src={channel.destination.definition.icon}
      />
      <LinkWithTooltip href={url} isExternal>
        {resource?.name}
      </LinkWithTooltip>
      {resource && definition.isInactive(resource) && (
        <Tooltip
          message={`${channel.destination.definition.name} ${resourceType} not activated`}
        >
          <Box as={ErrorIcon} boxSize={5} color="danger.base" flexShrink={0} />
        </Tooltip>
      )}
    </Row>
  );
};

export const useDestinationResource = ({ channel, resourceId }: Props) => {
  const { engine } = useOutletContext<OutletContext>();

  const { data, error, isLoading } = useDecisionEngineDestinationResourcesQuery(
    {
      destinationId: channel.destination.id,
    },
    { select: (data) => data.getDecisionEngineDestinationResources },
  );

  // TOOD: Replace destination resource queries with formkit queries
  const formkitQuery = useFormkitSyncHandlerQuery(
    {
      handler: "listObjects",
      variables: {
        type: "canvas",
      },
      context: {
        destinationId: Number(channel.destination.id),
        modelId: engine.segment.id,
      },
    },
    {
      enabled: channel.config.type === "canvas",
      select: (data) => data.formkitSyncHandler,
    },
  );

  const options = formkitQuery.data ?? data ?? [];

  const option =
    options.find(
      (c) =>
        String(c.id) === String(resourceId) ||
        String(c.value) === String(resourceId),
    ) ?? {};

  const resource = {
    id: option.id || option.value,
    name: option.name || option.label,
    status: option.status || "active",
  };

  const url = resource
    ? getChannelDefinition(channel.destination.type).getResourceUrl(
        resource,
        channel.destination.config,
        channel.config,
      )
    : "";

  return {
    url,
    resource,
    isLoading,
    error,
  };
};
